<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-button class="py-0 shadow-none btn-manual" size="sm" @click="valueChange(value - 1)">
        <b-icon icon="dash" font-scale="1.25" />
      </b-button>
    </b-input-group-prepend>

    <b-form-input
      :id="id"
      size="sm"
      :value="value"
      type="number"      
      min="0"
      class="text-center"
      number
      @change="valueChange"
      @keypress="isNumber($event)"
      @focus="$event.target.select()"
    />

    <b-input-group-append>
      <b-button class="py-0 shadow-none btn-manual" size="sm" @click="valueChange(value + 1)">
        <b-icon icon="plus" font-scale="1.25" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BIcon, BIconDash, BIconPlus } from 'bootstrap-vue'

export default {
  name: 'SpinbuttonManual', 

  components: {
    BIcon,

    /* eslint-disable vue/no-unused-components */
    BIconDash,
    BIconPlus
  },

  props: {
    id: {
      type: String,
      required: true
    },

    size: {
      type: String,
      required: false,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg'].includes(value)
      }
    },

    value: {
      type: Number,
      required: true
    }
  },

  methods: {
    valueChange (newValue) {
      if (newValue <= 0) {
        this.$emit('input', 0)
      } else {
        this.$emit('input', newValue)
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        console.log('-'+charCode)
        evt.preventDefault()
      } else {
        if(charCode==13){
            evt.target.blur()
            console.log('blur')
        }    
        console.log('+'+charCode)
        return true
      }
    }
  }
}
</script>

<style scoped>
/* Remove up and down arrows inside number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-group {
    flex-wrap: nowrap;
}    

input[type=number]{
    min-width: 33px;
}

.btn-manual{
    color: #000;
    border-color: #d2d2d2;
    background-color: white;
}
</style>