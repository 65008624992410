<template>
    <div class="freecomplex">

            <div v-if="complex_parts && !busy"> 
                <!--h3 class="mb-2"  v-if="complex_dish">
                    {{complex_dish.mnemo}}
                </h3-->    
                <template v-if="complex_parts && groups">
                    <!--b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            id="checkbox-group-2"
                            v-model="selected_parts"
                            :aria-describedby="ariaDescribedby"
                        -->
                            <template v-for="(grp,index) of Object.keys(groups)" >
                                <div  :key="grp">
                                    <div 
                                        class="group-title" 
                                        :class="'small freecomplex-style-'+index" 
                                        @click='clickGroupTitle(grp)'
                                    >
                                        <!--b-form-checkbox 
                                            v-model="sel[grp]"
                                            @click.prevent="false"
                                        >{{groups[grp]}}</b-form-checkbox-->
                                        <!--b-icon :icon="(sel[grp])?'check-square':'square'" class="mr-1"></b-icon-->

                                        <b-iconstack class="mr-2">
                                            <b-icon stacked icon="square-fill" variant="white"></b-icon>
                                            <b-icon stacked :icon="(sel[grp])?'check-square':'square'"></b-icon>
                                        </b-iconstack>{{groups[grp]}}

                                    </div>
                                    <div class="group-content" :class="'freecomplex-border-style-'+index" >    
                                        <b-collapse :id="'sel-'+grp" :key="grp" visible="sel[grp]">
                                            <div v-if="complex_parts[sel[grp]]" class="parts_checkbox">
                                                <div class="part-dish-title">{{(complex_parts[sel[grp]]).mnemo}}</div>
                                                <b-button 
                                                    @click="openPartList(grp)" 
                                                    role="button" 
                                                    variant="outline-dark bg-warning text-dark"
                                                    title="Обрати інше"
                                                ><b-icon icon="shuffle"></b-icon></b-button>
                                            </div>
                                        </b-collapse>    
                                        <b-collapse :id="'group-'+grp" appear :visible="!sel[grp]" :key="grp">
                                            <div class="parts-list">
                                                <div
                                                    @click="selectPart(cm.menuRN,grp)"
                                                    role="button"
                                                    no-gutters 
                                                    v-for="cm of complex_dish.complexParts.filter(item => item.menuGroupRN==grp)" 
                                                    :key="cm.menuRN" 
                                                    class="mb-2 part-item"
                                                >
                                                    <div class="d-none d-md-block">
                                                        <template v-if="complex_parts[cm.menuRN] && (complex_parts[cm.menuRN]).photoURL">
                                                            <b-img width="100" :src="(complex_parts[cm.menuRN]).photoURL" :alt="(complex_parts[cm.menuRN]).mnemo" class="mr-3"></b-img>
                                                        </template>  
                                                        <template v-else>
                                                            <b-img width="100" src='/img/empty.png' alt="Image" class="mr-3"></b-img>
                                                        </template>  
                                                    </div>
                                                    <div v-if="complex_parts[cm.menuRN]" class="part-dish">
                                                        <div class="part-dish-title">{{(complex_parts[cm.menuRN]).mnemo}}</div>
                                                        <div class="part-dish-info">{{(complex_parts[cm.menuRN]).info}}</div>
                                                        <div class="part-dish-outcome text-right small" v-if="(complex_parts[cm.menuRN]).outcome != null">
                                                            {{(complex_parts[cm.menuRN]).outcome}}
                                                        </div>
                                                        <div v-if="(complex_parts[cm.menuRN]).specmarkRNs.length>0" class="target-smrk">
                                                            <b-badge v-for="(smrk,index) of (complex_parts[cm.menuRN]).specmarkRNs" :key="index" :class="'mark_'+(specmarks[smrk]).name">
                                                                <template v-if="specmarks[smrk]!=null">{{(specmarks[smrk]).name}}</template>
                                                            </b-badge>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>    
                                        </b-collapse>
                                    </div>
                                </div>

                            </template>
                        <!--/b-form-checkbox-group>
                    </b-form-group-->
                </template>
            </div>
            <div v-else class="d-flex justify-content-center mb-3">
                <b-spinner variant="bruvis" label="Loading..."></b-spinner>
            </div>

        <template v-if="debugmode">
            <b-button v-b-toggle:partsDebug>Toggle Debug</b-button>
                <b-collapse id="partsDebug">
                    <h4>sel</h4>
                    <pre>{{sel}}</pre>

                    <h4>complex_dish</h4>
                    <pre>{{complex_dish}}</pre>

                    <h4>complex_parts</h4>
                    <pre>{{complex_parts}}</pre>

                    <h4>groups</h4>
                    <pre>{{groups}}</pre>
                </b-collapse>    
        </template>    
    </div>
</template>

<script>

export default {
  name: 'FreeComplex',
  props: {
    complex_dish:{
        type: Object,
        required: true
    },
    specmarks:{
        type: Object,
        required: true
    },
    debugmode:{
        type: Object,
        required: true,
    }

  },
  data: () => ({
    groups: null,
    busy: false,
    complex_parts: null,
    sel: [],
    seltmp: [],
    selected_parts: []
  }),  
  methods: {
    loadFreeComplex(freeComplex){
        
        this.busy = true
        var cmarr=[],groups={},sel={}

        freeComplex.complexParts.forEach((cm) => {
            cmarr.push(cm.menuRN)
            groups[cm.menuGroupRN]=cm.menuGroupMnemo
            sel[cm.menuGroupRN]=null
        })

        if(cmarr.length>0){
            console.log('Request for complex',cmarr)
            //--------------------------------------------
            var axiosConfig = {
            headers: {
              'Content-Type': 'application/json',
             'Authorization': 'Bearer_'+this.token
            },
            'withCredentials': true
        }
        var postData = {"action":"getfreecomplex","rn":cmarr.join(',')}
        this.$axios.post('/', postData,  axiosConfig)
                        
                .then((response) => {
                    if(response.data.success != 'false'){
                        this.complex_parts   = response.data.freecomplex
                      //var complex   = response.data.freecomplex
                      //Object.keys(complex).forEach((c)=>{
                      //  this.complex_parts[c] = complex[c]
                      //})

                      //this.$nextTick(() => {
                        this.busy = false
                        console.log('Complex loaded',this.complex_parts)
                        //this.globalbusy = false
                        //this.$bvModal.show('dish-target-modal')
                      //})
                      
                    }else{
                      this.busy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.$emit('needlogin')
                      }
                    }
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });




        //--------------------------------------------
        }else
            console.log('No need to Request for new info')

        this.busy=false

        this.groups = groups
        var subgroup

        Object.keys(groups).forEach((g) => {
            subgroup = this.complex_dish.complexParts.filter(item => item.menuGroupRN==g)
            if(subgroup.length===1){
                sel[g]=(subgroup[0]).menuRN
                this.switchPanel('group-'+g,'off')
                this.switchPanel('sel-'+g,'on')
            }    
        })
        
        this.sel = sel
        this.seltmp = sel

    },
    selectPart(x,grp){
        //console.log(x,grp)
        this.seltmp[grp]=x
        this.sel = this.seltmp
        //console.log(this.sel)
        this.switchPanel('group-'+grp,'off')
        this.switchPanel('sel-'+grp,'on')
    },
    openPartList(grp){
        this.seltmp[grp]=null
        this.sel = this.seltmp
        this.switchPanel('group-'+grp,'on')
        this.switchPanel('sel-'+grp,'off')
    },
    switchPanel(el,action){
      switch (action){
        case 'on':
          if(document.getElementById(el) && document.getElementById(el).style.display=='none')
            this.$root.$emit('bv::toggle::collapse', el)
          break
        case 'off':    
          if(document.getElementById(el) && document.getElementById(el).style.display!='none')
            this.$root.$emit('bv::toggle::collapse', el)
        }
    },
    clickGroupTitle(g){
        if(this.sel[g])this.openPartList(g)
    }

  },
  
  mounted() {
    this.loadFreeComplex(this.complex_dish)

  },

  computed: {
  
        
  }
}
</script>

<style scoped>

.freecomplex-style-0{
  background-color:#74d4a7 !important;
  border-color:#74d4a7 !important;
}
.freecomplex-style-1{
  background-color:#f5d313 !important;
  border-color:#f5d313 !important;
}
.freecomplex-style-2{
  background-color:#8cbae6 !important;
  border-color:#8cbae6 !important;
}
.freecomplex-style-3{
  background-color:#da7885 !important;
  border-color:#da7885 !important;
}
.freecomplex-style-4{
  background-color:#8d7397 !important;
  border-color:#8d7397 !important;
}

.freecomplex-border-style-0,
.freecomplex-border-style-1,
.freecomplex-border-style-2,
.freecomplex-border-style-3,
.freecomplex-border-style-4{
  border-width: 0.4em;
  border-radius:5px;
  border-style: solid;
}

.freecomplex-border-style-0{
  border-color:#74d4a7 !important;
}
.freecomplex-border-style-1{
  border-color:#f5d313 !important;
}
.freecomplex-border-style-2{
  border-color:#8cbae6 !important;
}
.freecomplex-border-style-3{
  border-color:#da7885 !important;
}
.freecomplex-border-style-4{
  border-color:#8d7397 !important;
}


.group-title{
    display: inline-block;
    padding: .5em 1em .3em .8em;
    margin-left: 1.3em;
    margin-top: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: default;
}
.group-content{
    padding: 0.9em;
}
.parts_checkbox{
    display:flex;
    justify-content: space-between;
}
.parts-list{
    background-color: white;;
}
.part-item{
    border:3px solid white;
    padding:.9em;
    display: flex;
}
.part-item img{
    border-radius: 8px;
}    
.part-item:hover{
    border:3px solid rgb(184, 184, 184);
    border-radius: 5px;
}
.part-dish{
    position: relative;
}
.part-dish-title{
    font-weight: bold;
    font-size: larger;
}
.part-dish-info{
    margin-top:.5em;
}


@media (max-width: 575px) {

}

@media (hover: none) {

}    


</style>
