<template>
<div>
    <b-overlay
        :show="ordereditbusy"
        rounded
        variant="white"
        blur="2px" 
        opacity="0.9"
        spinner-variant="bruvis"
        class="d-inline-block w-100"
        style="inset:0px;"
    >
        <table 
            class="table orders-table mb-0" 
            sticky-header="true" 
            v-if="order.length > 0"
        >
            <tbody>
                <template v-for="(zk,idx) in order">
                    <tr :key="zk" :class="(zk.quant==0)?'alert-danger text-danger':'text-black'" v-if="dishes[zk.menu_rn]">
                        <td class="wrdwrp" :id="'item_'+idx">{{zk.menu}}
                            <template v-if="zk.comments">
                                    <b-badge class="ml-1" variant="warning" v-if="zk.comments.split(';')[0]"> {{zk.comments.split(';')[0]}}</b-badge>
                                    <template v-if="zk.comments.split(';')[1]">
                                      <b-badge  class="ml-1" variant="warning" v-for="(cm,cmind) of zk.comments.split(';')[1].split(',')" :key=cmind>{{cm}}</b-badge>
                                    </template>  
                                  </template>      

                        </td>
                        <td class="align-top" width=1>
                            <b-icon icon="trash" 
                                @click="zk.quant=0" 
                                title="Видалити" 
                                variant="" 
                                class="h4 m-0" 
                                role="button">
                            </b-icon>
                        </td>
                        <td width=1>
                            <b-form-spinbutton 
                                v-if="!(salepoint.onlyOnePos && (dishes[zk.menu_rn]).complex)"
                                v-model="zk.quant"
                                @change="recalcAmountSum()" 
                                min="0" max="100" size="sm" :step="(dishes[zk.menu_rn]).minportion"
                                inline>
                            </b-form-spinbutton>
                        </td>
                    </tr>

                    <tr :key="zk" class="alert-warning text-secondary" v-else>
                        <td class="wrdwrp">{{zk.menu}}</td>
                        <td class="align-top" width=1>-</td>
                        <td width=1 class="text-center">x {{zk.quant}}</td>
                    </tr>


                </template>
                <tr class="border-top border-bottom" v-if="!salepoint.hidePrice">
                    <td>До сплати</td>
                    <td colspan="2" class="text-right ">{{amount_sum | toCurrency}}</td>
                </tr>  
                <tr class="pb-0">
                    <td colspan="3" align="right" class="pb-0">
                        <b-button 
                            class="btn btn-success m-1 ml-auto"
                            @click="editOrder()"
                        >
                            <b-icon icon="check2" class="mr-1"/>Ok
                        </b-button>
                        <b-button 
                            class="btn btn-bruvis m-1"
                            @click="$emit('close')"
                        >
                            <b-icon icon="x" class="mr-1"/>Відмінити
                        </b-button>
                    </td>
                </tr>        
            </tbody>        
        </table>
    </b-overlay>
</div>
</template>

<script>
export default {
  name: 'EditOrder',
  props: {
    order2edit: {
      type: Object,
      required: true
    },
    dishes: {
      type: Object,
      required: true
    },
    salepoint: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    ordereditbusy: false,
    order: [],
    order_filtered: [],
    amount_sum: 0
  }),
  mounted(){
    //this.order = this.order2edit
    var allExist = true
    this.order2edit.specs.forEach((itm) => {
        this.order.push({'rn':itm.rn,'menu_rn' : itm.menu_rn, 'menu': itm.menu, 'quant':itm.quant, 'price': itm.price, 'comments': itm.comments})
        if(!this.dishes[itm.menu_rn]) allExist = false
        //console.log((this.dishes[itm.rn]).price + 'x' +itm.quant )
        //this.amount_sum += this.dishes[itm.rn].price * itm.quant
    })
    this.recalcAmountSum()
    if(!allExist) this.$toasted.info('Увага! Деякі страви з вашого замовлення тимчасово відсутні').goAway(5000)
  },
  methods:{
    editOrder(){
        this.ordereditbusy = true
        this.order_filtered = this.order.filter(item => item.quant > 0)
        //console.log(this.order2edit)

        if(this.order_filtered.length > 0){
            this.$axios.post('/', {"action":"editorder","rn":this.order2edit.rn , "specs":this.order_filtered} )
            .then((response) => {
               if(response.data.success != false && response.data.success != 'false'){
                    this.$emit('onsuccess')
                    this.ordereditbusy = false
                    this.$toasted.success('Замовлення оновлено').goAway(2000)
                    this.$emit('close')
                }else{
                    if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.ordereditbusy = false
                        this.$emit('needlogin')
                    }else{
                        this.$bvModal.msgBoxOk(response.data.message, {
                            title: 'Попередження',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'bruvis',
                            okTitle: 'Ok',
                            footerClass: 'p-2',
                            hideHeaderClose: true,
                            centered: true,
                            headerClass: 'bg-danger text-white border-danger',
                        })
                        .then(value => {
                            console.log(value)
                            return
                        })
                        this.ordereditbusy = false
                    }
                }       
            })
            .catch(error => {
                this.ordereditbusy = false
                console.error('There was an error!', error)
            });
        }else{
            // remove empty order
            this.$axios.post('/', {"action":"removeorder","rn":this.order2edit.rn } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                        this.$emit('onsuccess')
                        this.ordereditbusy = false
                        this.$toasted.success(response.data.message).goAway(3000)
                        this.$emit('close')
                    }else{
                      this.ordereditbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.ordereditbusy = false
                        this.$emit('needlogin')
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });

        }    

    },
    recalcAmountSum() {
       var amnt = 0
       this.order.forEach((itm) => {
            if(this.dishes[itm.menu_rn])
                if((this.dishes[itm.menu_rn]).priceIncludingDiscount > 0){
                    amnt += (this.dishes[itm.menu_rn]).priceIncludingDiscount * itm.quant
                }else{    
                    amnt += (this.dishes[itm.menu_rn]).price * itm.quant
                }    
        })
        this.amount_sum = amnt
      return true
    }



  },
  computed: {

    
  }  
}
</script>

<style scoped>
.wrdwrp{
    word-break: break-all;
}

</style>
