<template>
  <div id="appc">
    <b-navbar type="dark" variant="bruvis" toggleable="lg" fixed="top" class="py-0 px-0" v-if="salepoints">
      <b-container>
        <b-navbar-brand @click="showDebug" v-if="logo" class="shadow-none">
          <b-iconstack font-scale="1.8" class="mx-2 d-inline d-md-none" variant="white" v-b-toggle.sidebar @click.prevent>
            <b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="4"></b-icon>
            <b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="0"></b-icon><b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="-4"></b-icon>
          </b-iconstack>
          <b-img :src="logo.img" height="40" class="mx-1 d-none d-md-inline"></b-img><b-img :src="logo.img_mobile" height="40" class="mx-1 d-inline d-md-none"></b-img><span class="d-none d-md-inline">{{logo.text}}</span>
        </b-navbar-brand>

        <!--b-navbar-nav>
          <b-nav-item class="text-white" small>
            <span class="d-block d-sm-none text-white">xs</span>
            <span class="d-none d-sm-block d-md-none text-white">sm</span>
            <span class="d-none d-md-block d-lg-none text-white">md</span>
            <span class="d-none d-lg-block d-xl-none text-white">lg</span>
            <span class="d-none d-xl-block text-white">xl</span>
          </b-nav-item> 
        </b-navbar-nav-->
        <b-navbar-nav class="ml-auto" style="flex-direction: row;">
          
          <!--b-button 
            variant="info" class="py-1 m-1 mx-0" 
            @click="getorders" 
            v-if="Object.keys(orders).length > 0 || Object.keys(checks).length > 0"
          >
            <b-icon icon="credit-card" animation="fade" variant="" v-b-popover.hover.bottomleft="'Є неоплачені замовлення!'"></b-icon>
          </b-button-->

          <!--b-nav-item 
            v-show="cart_count > 0" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <span style="font-size: small;" v-show="cart_sum > 0">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
            <b-icon icon="cart4"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
          </b-nav-item-->

          <!------------------------------------>

          <b-nav-item
            class="py-0 m-1 mx-0" 
            @click="getorders"  
            v-if="zakazy && Object.keys(zakazy).length > 0"
          >
            <template v-if="!salepoint.hidePrice">
              <b-button 
                :class="(!overLimit || (salepoint && salepoint.creditCardPayment && !salepoint.clubCardPayment))?'btn btn-victor':(salepoint && salepoint.combiPay)?'btn btn-victor':'btn btn-danger'"
                v-b-popover.hover.bottomleft.v-victor="{ 
                  customClass: 'bg-victor text-black font-weight-bold popover', 
                  content : (!overLimit || (salepoint && salepoint.creditCardPayment && !salepoint.clubCardPayment))?'Є неоплачені замовлення!':(salepoint && salepoint.combiPay)?'Є неоплачені замовлення, що потребують додаткової оплати!':'Є неоплачені замовлення, що перевищують ліміт!'
                }"
              >
              <!--template v-if="overLimit && salepoint.combiPay===false"-->
              <template v-if="overLimit && salepoint && !salepoint.creditCardPayment">  
                <b-icon  icon="exclamation-triangle-fill" variant="light" animation="fade"/>
              </template>
              <template v-else-if="overLimit && salepoint && salepoint.combiPay">  
                <b-iconstack  variant="dark">
                  <b-icon stacked icon="credit-card" scale="0.9" shift-h="-2" shift-v="-2"/>    
                  <b-icon stacked icon="circle-fill" scale="0.7" shift-h="2" shift-v="2" animation="fade" variant="light"/>
                  <b-icon stacked icon="coin" scale="0.7" shift-h="2" shift-v="2" animation="fade" flip-v></b-icon>
                </b-iconstack>
              </template>
              <template v-else>
                <b-icon icon="credit-card" animation="fade"/>
              </template>
              </b-button>
            </template>

            <template v-else>
              <b-button 
                class="btn btn-victor"
                v-b-popover.hover.bottomleft.v-victor="{ 
                  customClass: 'bg-victor text-black font-weight-bold popover', 
                  content : 'Дійсні замовлення'
                }"
              >
                <b-iconstack  variant="dark">
                  <b-icon stacked icon="cart-check" scale="1" shift-v="-2"/>    
                </b-iconstack>
              </b-button>
            </template>  

          </b-nav-item>

          <!------------------------------------>

          <b-nav-item 
            v-show="cart_count > 0"
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :(!this.settings.ask_when_basket_not_empty)?'Вміст кошика при зміні дня та часу - не зберігається!':''
            }"
            id="cart-button" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <b-icon icon="cart4" class="mr-2"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
            <span class="head-txt" v-show="cart_sum > 0" v-if="salepoint && !salepoint.hidePrice">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
          </b-nav-item>

          <!------------------------------------>

          <template v-if="!settings || !settings.disable_ballance_icon">

            <b-nav-item 
              v-if="clubcards && clubcards.length > 0 && (!limits || limits['countlimits']==0)" 
              v-b-popover.hover.bottomleft.v-victor="{ 
                customClass: 'bg-victor text-black font-weight-bold popover', 
                content :((clubcards[0]).balance < 0 && (clubcards[0]).cardlimit > 0)?'Доступний кредит по клубній картці: '+ Math.round( ((clubcards[0]).cardlimit + (clubcards[0]).balance)* 100) / 100 + ' грн':'Баланс на клубній картці'
              }"
              class="py-1 m-1 button-cart text-white" 
            >
              <b-icon icon="piggy-bank-fill" flip-h class="mr-1" :variant="((clubcards[0]).balance > 0)?'light':'warning'"></b-icon>
              <span class="head-txt">{{ (clubcards[0]).balance }}<span>&#8372;</span>&nbsp;</span> 
              
            </b-nav-item>
            <!------------------------------------>
            <b-nav-item 
              v-if="clubcards && clubcards.length > 0 && salepoint && !salepoint.creditCardPayment && salepoint.clubCardPayment"
              id="current-limits" 
              class="py-1 m-1 button-cart text-white" 
            >
              <!-- 
                v-b-popover.hover.bottomleft.v-victor="{ 
                customClass: 'bg-victor text-black font-weight-bold popover', 
                content :(limits && limits[ondate] && (limits[ondate]).dayLimit > 0 )?ondate+' Доступний баланс на день: '+ Math.round( ((limits[ondate]).balancePerDay)* 100) / 100 + ' грн':((limits && limits[ondate] && (limits[ondate]).weekLimit > 0 )?'Доступний баланс на тиждень: '+ Math.round( ((limits[ondate]).balancePerWeek)* 100) / 100 + ' грн':((limits && limits[ondate] && (limits[ondate]).monthLimit > 0 )?'Доступний баланс на місяць: '+ Math.round( ((limits[ondate]).balancePerMonth)* 100) / 100 + ' грн':'---'))
              }"
              -->
              <b-icon 
                icon="cash-coin" flip-h class="mr-1" 
                :variant="((clubcards[0]).balance > 0)?'light':'warning'"
                v-if="limits && limits[ondate] && ((limits[ondate]).dayLimit > 0 || (limits[ondate]).weekLimit > 0 || (limits[ondate]).monthLimit > 0)"
              >
              </b-icon>
              <!--span class="head-txt" v-if="limits && limits[ondate] && (limits[ondate]).dayLimit > 0">{{ (limits[ondate]).balancePerDay }} <span>&#8372;</span>&nbsp;</span>
              <span class="head-txt" v-if="limits && limits[ondate] && (limits[ondate]).weekLimit > 0">{{ (limits[ondate]).balancePerWeek }} <span>&#8372;</span>&nbsp;</span>
              <span class="head-txt" v-if="limits && limits[ondate] && (limits[ondate]).monthLimit > 0">{{ (limits[ondate]).balancePerMonth }} <span>&#8372;</span>&nbsp;</span-->    
              <!--template v-if="limits && limits[ondate] && limits['countlimits']==1">
                <span class="head-txt" v-if="limits['dayLimit']==1">{{ (limits[ondate]).balancePerDay }} <span>&#8372;</span>&nbsp;</span>
                <span class="head-txt" v-else-if="limits['weekLimit']==1">{{ (limits[ondate]).balancePerWeek }} <span>&#8372;</span>&nbsp;</span>
                <span class="head-txt" v-else-if="limits['monthLimit']==1">{{ (limits[ondate]).balancePerMonth }} <span>&#8372;</span>&nbsp;</span>    
              </template-->
              <template v-if="limits && limits[ondate] && limits['countlimits']>0">
                <span class="head-txt">{{ Math.min((limits[ondate]).balancePerDay,(limits[ondate]).balancePerWeek,(limits[ondate]).balancePerMonth) }} <span>&#8372;</span>&nbsp;</span>
              </template>    
            </b-nav-item>
          </template>

          <b-popover
            v-if="clubcards && clubcards.length > 0"
            target="current-limits"
            triggers="hover"
            placement="auto"
          >
            <template #title>
              <b-button class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              {{new Date(ondate) | dateFormat('dddd D MMMM')}}
            </template>

            <div>
              <div>Доступний баланс</div>
              <div v-if="limits && limits[ondate] && (limits[ondate]).dayLimit>0" class="limits-popover"><div>На день</div><div>{{ (limits[ondate]).balancePerDay | toCurrency}}</div></div>
              <div v-if="limits && limits[ondate] && (limits[ondate]).weekLimit>0" class="limits-popover"><div>На тиждень</div><div>{{ (limits[ondate]).balancePerWeek | toCurrency}}</div></div>
              <div v-if="limits && limits[ondate] && (limits[ondate]).monthLimit>0" class="limits-popover"><div>На місяць</div><div>{{ (limits[ondate]).balancePerMonth | toCurrency}}</div></div>
              <div v-if="clubcards && clubcards[0]" class="limits-popover"><div>Залишок</div><div>{{ Math.round( ((clubcards[0]).cardlimit + (clubcards[0]).balance)* 100) / 100 | toCurrency}}</div></div>
            </div>
          </b-popover>    

          <!-----------------
          
          Math.min(Math.round( ((clubcards[0]).cardlimit + (clubcards[0]).balance)* 100) / 100,
          
          Math.round( ((limits[ondate]).balancePerDay)* 100) / 100
          Math.round( ((limits[ondate]).balancePerWeek)* 100) / 100
          Math.round( ((limits[ondate]).balancePerMonth)* 100) / 100
          
          ------------------->



          <!--b-button 
            v-show="cart_count > 0" 
            variant="info" class="py-1 m-1 button-cart" 
            @click="$bvModal.show('basket-modal2')" 
            :disabled="cart_count == 0"
          >
            <span style="font-size: small;" v-show="cart_sum > 0">{{ cart_sum }} <span>&#8372;</span>&nbsp;</span> 
            <b-icon icon="cart4"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="cart_count > 0">{{ cart_count }}</div> 
          </b-button-->
   
          <b-dropdown v-show="!needlogin" right variant="bruvis" class="p-0 pr-2 m-0 d-none d-md-inline">
          <!--b-dropdown right variant="bruvis" :class="(needlogin)? 'd-none':'p-0 pr-2 m-0 d-none d-md-inline'"-->  
            <template #button-content>
              <b-icon icon="person-fill"></b-icon><span class="d-none d-sm-inline p-0 m-0"> <template v-if="user && user.mnemo">{{user.mnemo}}</template><template v-else>{{username}}</template></span>
            </template>
            <b-dropdown-item v-b-modal.userprofile-modal v-if="!settings || !settings.hide_profile">
              <b-icon icon="person-badge" scale="1" class="mr-1"></b-icon>
              Профіль користувача
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.zakazy-payed-modal v-if="!settings || !settings.hide_orders">
              <b-icon icon="bag-check" scale="1" class="mr-1"></b-icon>
              Оплачені замовлення
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.archive-modal v-if="!settings || !settings.hide_archive">
              <b-icon icon="box-seam" scale="1" class="mr-1"></b-icon>
              Архів замовлень
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <template v-if="settings.menu_user_blocks">
              <b-dropdown-item 
                v-for="(bl,idx) in settings.menu_user_blocks" 
                :key="idx"
                @click="user_block_title = bl.title,user_block_rn=idx,$bvModal.show('user-block-modal')"
              >
                <b-icon icon="file-text" scale="1" class="mr-1"></b-icon>
                {{bl.title}}
              </b-dropdown-item>
            </template>  

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#" @click="logout">
              <b-iconstack font-scale="1" class="mr-1">
                <b-icon stacked icon="door-open" variant="dark"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon>
              </b-iconstack>
              Вихід
            </b-dropdown-item>
          </b-dropdown>
       

        </b-navbar-nav>  
      </b-container>
    </b-navbar>

    <b-sidebar id="sidebar" :title="(user && user.mnemo)?user.mnemo:''" bg-variant="bruvis" text-variant="white" backdrop-variant="light" backdrop shadow>
      <div class="px-3 py-2">
        <b-button-group vertical size="lg">
          <b-button class="text-left" variant="bruvis" v-b-modal.userprofile-modal v-b-toggle.sidebar v-if="!settings || !settings.hide_profile"><b-icon icon="person-badge" scale="1" class="mr-1"></b-icon> Профіль користувача</b-button>
          <b-button class="text-left" variant="bruvis" v-b-modal.zakazy-payed-modal v-b-toggle.sidebar  v-if="!settings || !settings.hide_orders"><b-icon icon="bag-check" scale="1" class="mr-1"></b-icon> Оплачені замовлення</b-button>
          <b-button class="text-left" variant="bruvis" v-b-modal.archive-modal v-b-toggle.sidebar  v-if="!settings || !settings.hide_archive"><b-icon icon="box-seam" scale="1" class="mr-1"></b-icon> Архів замовлень</b-button>
          <template v-if="settings.menu_user_blocks">
            <b-button 
              class="text-left" 
              variant="bruvis" 
              v-b-toggle.sidebar
              v-for="(bl,idx) in settings.menu_user_blocks" 
              :key="idx"
              @click="user_block_title = bl.title,user_block_rn=idx,$bvModal.show('user-block-modal')"
            >
              <b-icon icon="file-text" scale="1" class="mr-1"></b-icon>
              {{bl.title}}
            </b-button>
          </template> 
          <b-button class="text-left" variant="bruvis" @click="logout" v-b-toggle.sidebar><b-iconstack font-scale="1" class="mr-1"><b-icon stacked icon="door-open" variant="light"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon></b-iconstack> Вихід</b-button>
        </b-button-group>
      </div>
    </b-sidebar>





    <div v-if="!salepoints && !needlogin && !globalbusy">
      <div class="alert alert-warning">Немає доступу до жодної точки продажу</div>      
      <b-button @click="logout">
              <b-iconstack font-scale="1" class="mr-1">
                <b-icon stacked icon="door-open"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon>
              </b-iconstack>
              Вихід
      </b-button>
  
    </div>      

    <b-overlay
      :show="globalbusy && !needlogin"
      rounded
      variant="white"
      blur="2px" 
      opacity="0.7"
      spinner-variant="bruvis"
      class="d-inline-block w-100"
      no-center
      style="inset:0px;"
      id = "globaloverlay"
    >
    <b-container class="main__container" :aria-hidden="globalbusy"  v-if="salepoints">
      <b-row>
<!---------------------------------------------------------------------------------------------->
        <!--b-col lg="12">
          <b-row-->
            <!--b-col v-if="salepoints.length > 1">
                <b-form-select
                  
                  v-model="salepoint_menu_rn"
                  :options="salepoints"
                  class="mb-2"
                  value-field="rn"
                  text-field="posname"
                  @change = "changeSalePoint(salepoint_menu_rn)"
                ></b-form-select>
            </b-col-->
            <!--b-col>
                <b-form-select
                  v-if="mealtimes.length !== 0"
                  v-model="mealtime"
                  class="mb-2"
                  @change = "changeMealtime(mealtime)"
                >
                  <template v-for="(mt,index) of  Object.keys(mealtimes)">
                    <b-form-select-option 
                      :key="index" 
                      :value="(mealtimes[mt]).rn"
                      :disabled="((mealtimes[mt]).timeTo <= (new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate() === (new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"
                    >
                      {{(mealtimes[mt]).mnemo}}
                    </b-form-select-option>
                  </template>  
                
                </b-form-select>
            </b-col-->

          <!-- (new Date()).getHours()-5 + (new Date()).getMinutes()/100  ) -->
          <!--  && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate()) == ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate()) -->
          <!--:options="mealtimes.filter(item => item.timeTo >= (new Date()).getHours() + (new Date()).getMinutes()/100))"-->
            <!--b-col>
              <b-form-datepicker
                v-if="salepoints.length !== 0"
                @input = "changeOndate(ondate)" 
                v-model="ondate" 
                :date-disabled-fn="dateDisabled"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" 
                locale="uk" 
                placeholder="Оберіть дату"
                start-weekday="1"
                :min= "minOndate"
                :max= "maxOndate"
                class="shadow-none mb-2"
                hide-header
                label-prev-decade="label1"
                label-next-decade="label2"
                ></b-form-datepicker>
            </b-col-->
          <!--/b-row>  

          
        </b-col-->
<!---------------------------------------------------------------------------------------------->        
<!--             @input.prevent = "changeOndate(ondates[ondateTabIndex])"  -->

        <b-col lg="12">
<!--
            <b-tabs 
              ref="ondates-tabs" 
              card 
              nav-class="scrollable-tabs-nav"
              v-model="ondateTabIndex"
            >
              <template v-slot:tabs-start>
                <b-icon icon="arrow-left-square-fill" variant="dark" class="h3"></b-icon>
              </template>
              <b-tab 
                v-for="(dt,tbinxd) in ondates" 
                :title="new Date(dt) | dateFormat('dddd D MMMM')" 
                title-item-class="text-nowrap"
                :key = "dt"
                @click.prevent = "changeOndate(dt)"
                :active="ondateTabIndexBuffer === tbinxd"
                content-class="mt-3"
              >
            </b-tab>
              <template v-slot:tabs-end>
                <b-icon icon="arrow-right-square-fill" variant="dark" class="h3"></b-icon>
              </template>
          </b-tabs>
-->
        <div class="w-100 mb-4 d-none d-sm-block">
          <b-tabs 
            ref="ondates-tabs"
            id="ondates-tabs" 
            pills 
            nav-class="scrollable-tabs-nav"
            active-nav-item-class = "btn btn-bruvis"
            v-if="salepoint"
          ><!--            v-if="ondates"-->
            <template #tabs-start>
              <button 
                ref="scrollLeft" 
                class="vps-tabs-nav-btn-left btn btn-victor text-bruvis border-bruvis" 
                @click="clickLeft"
              >
                <b-icon icon="caret-left-fill"></b-icon>
              </button>
            </template>
            <!--b-tab 
              v-for="(dt,tbinxd) in ondates" 
              :title="new Date(dt) | dateFormat('dddd D MMMM')" 
              :ref="new Date(dt) | dateFormat('dddd D MMMM')"
              :class="(tbinxd === 0 )?'first':((tbinxd === ondates.length-1)?'last':'')"
              title-item-class="text-nowrap"
              :key = "dt"
              @click.prevent = "changeOndate(dt)"
              :active="ondateTabIndexBuffer === tbinxd"
              content-class="mt-3"
            >
            </b-tab-->
<!--                :class="(tbinxd === 0 )?'first':((tbinxd === ondates.length-1)?'last':'AAA')"-->            
            <template v-for="(dt,tbinxd) in ondates" >
              <b-tab
                :active="ondateTabIndexBuffer === tbinxd"
                :key = "dt"
                @click.prevent = "changeOndate(dt)"
                :title-item-class="(tbinxd === 0 )?'text-nowrap first shadow-none':((tbinxd === ondates.length-1)?'text-nowrap last shadow-none':'text-nowrap shadow-none')"
              >
                <template #title>
                  {{new Date(dt) | dateFormat('dddd D MMMM')}}
                  <div v-if="zakazy_payed_actual[dt]" class="zakazy-day-quant">{{(zakazy_payed_actual[dt]).length}}</div>
                </template>
              </b-tab>
            </template>  
            <template #tabs-end>
              <button 
                ref="scrollRight" 
                class="vps-tabs-nav-btn-right btn btn-victor text-bruvis border-bruvis" 
                @click="clickRight"
              >
                <b-icon icon="caret-right-fill"></b-icon>
              </button>
            </template>
          </b-tabs>
        </div>

          <!--MAIN WINDOW BEGIN------------------------
          :nav-wrapper-class="(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'col-lg-0 col-md-0 col-sm-12 col-xs-12 w-100':'col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100'"
          nav-wrapper-class="col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100"
          -->
          <b-row>  
            <div v-if="salepoints.length == 0" class="m-0 my-4 py-4 d-none d-sm-block" style="height:3000px"></div>
            <b-card no-body class="w-100 min-vh-70 p-2 border-0" v-if="posts.length !== 0">
              <template v-if="salepoint">
                <h6 class="alert alert-danger m-0 mb-3" v-if="alertmessage">{{ alertmessage }}</h6>              
              </template>
              <template v-else>
                <h6 class="alert alert-danger m-0 mb-3">Немає доступу до жодної точки продажу!</h6>              
              </template>
              <!--v-if="Object.keys(categories).length > 0" -->
              <b-tabs 
                v-if="salepoints"
                v-model="catIndex" 
                pills
                vertical
                :nav-wrapper-class="(salepoints.length === 1 && mealtimes && Object.keys(mealtimes).length === 1 && categories && Object.keys(categories).length === 1)?'col-lg-0 col-md-0 col-sm-12 col-xs-12 w-100':'col-lg-3 col-md-4 col-sm-12 col-xs-12 w-100'"
                active-nav-item-class = "btn btn-bruvis"
                content-class=""
                nav-class="tabs-nav-cetegories"
              >
                <template v-slot:tabs-start>
                  <b-form-select
                    v-if="salepoints.length > 1"
                    v-model="salepoint_menu_rn"
                    :options="salepoints"
                    class="mb-2 shadow-none"
                    value-field="rn"
                    text-field="posname"
                    @change = "changeSalePoint(salepoint_menu_rn)"
                  />

                  <div class="date-block d-sm-none">
                    <div
                      v-if="salepoints.length !== 0" 
                      role="button" 
                      class="date-prev-btn btn btn-victor border-bruvis" 
                      @click="setPrevDay()"  
                      title="Попередній день"
                      :disabled="ondateTabIndex === 0"
                    >
                      <b-icon :icon="(ondateTabIndex === 0)?'stop':'caret-left-fill'"></b-icon>
                    </div>
                    <div class="datepicker">  
                      <b-form-datepicker
                        v-if="salepoints.length !== 0"
                        @input = "changeOndate(ondate)" 
                        v-model="ondate" 
                        :date-disabled-fn="dateDisabled"
                        :date-format-options="{ month: 'long', day: 'numeric', weekday: 'long' , year: 'numeric'}" 
                        locale="uk" 
                        placeholder="Оберіть дату"
                        start-weekday="1"
                        :min= "minOndate"
                        :max= "maxOndate"
                        class="shadow-none border-bruvis mb-2 d-sm-none"
                        style="font-size:0.8em;height:3em;"
                        hide-header
                        label-prev-decade="label1"
                        label-next-decade="label2"
                      />
                    </div>
                    <div 
                      v-if="salepoints.length !== 0"
                      role="button" 
                      class="date-next-btn btn btn-victor border-bruvis" 
                      @click="setNextDay()" 
                      title="Наступний день"
                      :disabled="ondateTabIndex === ondates.length-1"
                    >
                      <b-icon :icon="(ondateTabIndex === ondates.length-1)?'stop':'caret-right-fill'"></b-icon>
                    </div>
                  </div>
<!--v-if="mealtimes.length !== 0"-->
                    <b-form-select
                      v-if="salepoints.length > 0 && Object.keys(mealtimes).length > 1"
                      v-model="mealtime"
                      class="mb-2 shadow-none"
                      @change = "changeMealtime(mealtime)"
                    >
                      <template v-for="(mt,index) of  Object.keys(mealtimes)">
                        <b-form-select-option 
                          :key="index" 
                          :value="(mealtimes[mt]).rn"
                          :disabled="((mealtimes[mt]).timeTo <= (new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(ondate)).getFullYear()+(new Date(ondate)).getMonth()+(new Date(ondate)).getDate() === (new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"
                        >
                          {{(mealtimes[mt]).mnemo}}
                        </b-form-select-option>
                      </template>  
                    </b-form-select>
<!-------------------------------------------->
                     <b-form-select
                        v-if="Object.keys(categories).length > 0"
                        v-model="catIndex"
                        :class="(Object.keys(categories).length > 1)?'d-block d-md-none':'d-none'"
                      >
                        <template v-for="(ct,ctind) of  Object.keys(categories)">
                          <b-form-select-option 
                            :key="ctind" 
                            :value="ctind"
                          >
                            {{(categories[ct]).mnemo}}
                          </b-form-select-option>
                        </template>

                     </b-form-select>

<!-------------------------------------------->
                </template>
                <template v-if="Object.keys(categories).length > 0" >
                  <b-tab 
                    v-for="(cat, index) of categories" 
                    :key="index" 
                    :title="cat.mnemo"
                    :title-link-class="(Object.keys(categories).length > 1)?'d-none d-md-block shadow-none':'d-none'"
                  >
                    <div class="text-right" v-if="!salepoint.disable_sorting && !salepoint.hidePrice">
                      <b-button @click="dishes_sort('mnemo_asc')" :class="(sort_mode == 'mnemo_asc')?'m-2 bg-victor text-dark shadow-none':'m-2 bg-white text-dark shadow-none'" title="Сортувати за назвою">
                        <b-icon icon="sort-alpha-down" />                      
                      </b-button>
                      <b-button @click="dishes_sort('price_asc')" :class="(sort_mode == 'price_asc')?'m-2 bg-victor text-dark shadow-none':'m-2 bg-white text-dark shadow-none'" title="Сортувати за зростанням вартості">  
                        <b-icon icon="sort-down-alt" />
                      </b-button>  
                      <b-button @click="dishes_sort('price_desc')" :class="(sort_mode == 'price_desc')?'my-2 ml-2 bg-victor text-dark shadow-none':'my-2 ml-2 bg-white text-dark shadow-none'" title="Сортувати за зменьшенням вартості">  
                        <b-icon icon="sort-down" />
                      </b-button>    
                    </div>
                    <b-row>
                    <template v-for="(dish,index2) of dishes_test">  
                      <b-col
                        xs="12" sm="6"
                        :xl = "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'2':'3'"
                        :lg =  "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'2':'3'"
                        :md =  "(salepoints.length === 1 && mealtimes.length === 1 && Object.keys(categories).length === 1)?'3':'4'"
                        class="mb-2" v-if="cat.childs.indexOf(dish.rp)!==-1" :key="index2"
                      >

                        <div 
                          :class="(dish.quant_cart > 0)?'my-1 mx-0 p-0 border border-success rounded card-dish':'my-1 mx-0 p-0 border rounded card-dish'"
                          @mouseover ="complexBannerVisibility(dish.rn,'off')"
                          @mouseleave="complexBannerVisibility(dish.rn,'on')"
                        >

                          <!--------------------------------------------------> 
                          <!--
                          <b-aspect aspect="3:2" class="dish-banner rounded-top">
                            <b-img @click="showDish(dish.rn)" role="button" :src="(dish.photoExists)?dish.photoURL:((dish.complex && (dish.complexType===2 || dish.complexType===1))?'/img/basket_'+dish.complexType+'.png':'/img/empty.png')" class="w-100"   style="position:absolute;left:0"></b-img>
                          </b-aspect>

                          <b-aspect aspect="16:9" class="card-dish-legend">

                            <div class="dish-mnemo">{{dish.mnemo}}</div>
                            <div class="price-outcome" v-if="dish.outcome">{{dish.outcome}}</div>
                            <div class="price" v-if="!salepoint.hidePrice">
                              <span class="price-uah">{{dish.priceInt}}</span><span class="price-kop">{{dish.priceKop}}</span><span class="price-hrn">грн</span>
                            </div>

                            <div @click="addCart(dish.rn)" class="add_cart_item" role="button">
                              <b-iconstack class="h3" v-if="dish.busy_button_state && dish.busy_button_state===1">
                                <b-icon stacked icon="cart-fill" variant="white"></b-icon>
                                <b-icon stacked icon="cart" variant="secondary"></b-icon>
                                <b-icon stacked icon="arrow-repeat" scale="0.4" variant="dark" animation="spin"></b-icon>
                              </b-iconstack>
                              <b-icon v-else icon="cart-plus" variant="dark" class="h3"></b-icon>
                              <div class="item_quant" v-show="dish.quant_cart > 0">{{ dish.quant_cart }}</div>  
                            </div>

                            <b-iconstack @click="setCartQuant(dish.rn, 0)" role="button" v-show="dish.quant_cart > 0" class="h4 ico-remove-item">
                              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                              <b-icon stacked icon="x-circle" variant="dark"></b-icon>
                            </b-iconstack>

                            <div v-if="dish.specmarkRNs.length>0" class="dish_smarks">
                              <template v-for="(smrk,index3) of dish.specmarkRNs">
                                <b-badge  :key="index3" v-if="specmarks[smrk]!=null" :class="'mark_'+(specmarks[smrk]).name">
                                  {{(specmarks[smrk]).name}}
                                </b-badge>
                              </template>  
                            </div>

                            <div v-if="dish.complex" class="dish_complex">
                              <b-iconstack font-scale="1">
                                <b-icon
                                  stacked
                                  icon="circle-fill"
                                  variant="white"
                                  scale="1.5"
                                ></b-icon>
                                <b-icon
                                  stacked
                                  icon="circle"
                                  variant="bruvis"
                                  scale="1.5"
                                ></b-icon>
                                <b-icon
                                  stacked
                                  icon="stack"
                                  variant="bruvis"
                                ></b-icon>
                              </b-iconstack>  
                            </div>
                          </b-aspect>
                          -->
                          <!------------------->
                          <!--:title="$screens({ default: 3, xl: 0.8, lg: 0.8, md: 0.8, sm: 2})"-->

                          <!-- Variant 2 BEGIN -->
                          <!--
                          <b-aspect 
                            :aspect="$screens({ default: 3, xl: 0.8, lg: 0.8, md: 0.8, sm: 2})"
                            class="card-dish-legend"
                          >
                            <b-aspect 
                              :aspect="(dish.complex && dish.complexType===1)?'4':'3:2'" 
                              class="dish-banner rounded-top"
                              <b-img @click="showDish(dish.rn)" role="button" :src="(dish.photoExists)?dish.photoURL:((dish.complex && (dish.complexType===2 || dish.complexType===1))?'/img/basket_'+dish.complexType+'.png':'/img/empty.png')" class="w-100"   style="position:absolute;left:0"></b-img>
                            </b-aspect>


                            <div class="dish-mnemo mb-2">{{dish.mnemo}}</div>

                            <template v-if="dish.complex && dish.complexType===1">
                              <div 
                                class="d-block d-md-none"
                                @click="showDish(dish.rn)"
                              >
                                <template v-for="cm of dishes[dish.rn].complexParts">
                                  <div v-if="dishes_complex[cm.menuRN]" :key="cm.menuRN" class="mini_list_parts ml-2">
                                    <b-icon icon="check" class="mr-1"></b-icon>{{(dishes_complex[cm.menuRN]).mnemo}}
                                  </div>
                                </template>
                              </div>  
                              <div 
                                class="d-none d-md-block"
                                @click="showDish(dish.rn)"
                                role="button"
                              >
                                <template v-for="(cm,idx) of dishes[dish.rn].complexParts">
                                  <div v-if="dishes_complex[cm.menuRN] && idx < 2" :key="cm.menuRN" class="mini_list_parts ml-2 small">
                                    <b-icon icon="check" class="mr-1"></b-icon>{{(dishes_complex[cm.menuRN]).mnemo}}
                                  </div>
                                  <div v-if="idx === 2" :key="cm.menuRN" class="text-center m-0 p-0">
                                    <b-iconstack >
                                      <b-icon stacked icon="record-fill" variant="dark" scale="0.3"></b-icon>
                                      <b-icon stacked icon="record-fill" variant="dark" shift-h="10" scale="0.3"></b-icon>
                                      <b-icon stacked icon="record-fill" variant="dark" shift-h="-10" scale="0.3"></b-icon>
                                    </b-iconstack>
                                  </div>
                                </template>
                              </div>  
                            </template>  

                            <div class="price-outcome" v-if="dish.outcome">{{dish.outcome}}</div>
                            <div class="price" v-if="!salepoint.hidePrice">
                              <span class="price-uah">{{dish.priceInt}}</span><span class="price-kop">{{dish.priceKop}}</span><span class="price-hrn">грн</span>
                            </div>

                            <div @click="addCart(dish.rn)" class="add_cart_item" role="button">
                              <b-iconstack class="h3" v-if="dish.busy_button_state && dish.busy_button_state===1">
                                <b-icon stacked icon="cart-fill" variant="white"></b-icon>
                                <b-icon stacked icon="cart" variant="secondary"></b-icon>
                                <b-icon stacked icon="arrow-repeat" scale="0.4" variant="dark" animation="spin"></b-icon>
                              </b-iconstack>
                              <b-icon v-else icon="cart-plus" variant="dark" class="h3"></b-icon>
                              <div class="item_quant" v-show="dish.quant_cart > 0">{{ dish.quant_cart }}</div>  
                            </div>

                            <b-iconstack  @click="setCartQuant(dish.rn, 0)" role="button" v-show="dish.quant_cart > 0" class="h4 ico-remove-item">
                              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                              <b-icon stacked icon="circle" variant="dark"></b-icon>
                              <b-icon stacked icon="trash" variant="dark" scale="0.6"></b-icon>
                            </b-iconstack>

                            <div v-if="dish.specmarkRNs.length>0" class="dish_smarks">
                              <template v-for="(smrk,index3) of dish.specmarkRNs">
                                <b-badge  :key="index3" v-if="specmarks[smrk]!=null" :class="'mark_'+(specmarks[smrk]).name">
                                  {{(specmarks[smrk]).name}}
                                </b-badge>
                              </template>  
                            </div>

                            <div v-if="dish.complex" class="dish_complex">
                              <b-iconstack font-scale="1">
                                <b-icon
                                  stacked
                                  icon="circle-fill"
                                  variant="white"
                                  scale="1.5"
                                ></b-icon>
                                <b-icon
                                  stacked
                                  icon="circle"
                                  variant="bruvis"
                                  scale="1.5"
                                ></b-icon>
                                <b-icon
                                  stacked
                                  icon="stack"
                                  variant="bruvis"
                                ></b-icon>
                              </b-iconstack>  
                            </div>
                          </b-aspect>-->
                          <!-- Variant 2 end -->

                          <!-- Variant 3 BEGIN -->
                          <b-aspect 
                            :aspect="$screens({ default: 3, xl: 0.8, lg: 0.8, md: 0.8, sm: 2})"
                            class="card-dish-legend"
                          >
                            <div class="dish_card">  
                              <!--------------------->
                              <template v-if="(dish.complex && dish.complexType===1)">
                                <!-- 4 desktop -->
                                <div class="d-none d-md-block">
                                  <b-collapse :id="'complex-banner-'+ dish.rn" visible v-if="!settings.hide_fixed_complex_banner">
                                    <b-aspect 
                                      aspect="3:2"
                                      class="dish-banner rounded-top"
                                    >
                                      <b-img @click="showDish(dish.rn)" role="button" :src="(dish.photoExists)?dish.photoURL:((dish.complex && (dish.complexType===2 || dish.complexType===1))?'/img/basket_'+dish.complexType+'.png':'/img/empty.png')" class="w-100"   style="position:absolute;left:0"></b-img>
                                    </b-aspect>
                                  </b-collapse>
                                </div>
                                <!-- 4 mobile -->    
                                <b-aspect 
                                  aspect="3:2"
                                  class="dish-banner rounded-top d-block d-md-none"
                                >
                                  <b-img @click="showDish(dish.rn)" role="button" :src="(dish.photoExists)?dish.photoURL:((dish.complex && (dish.complexType===2 || dish.complexType===1))?'/img/basket_'+dish.complexType+'.png':'/img/empty.png')" class="w-100"   style="position:absolute;left:0"></b-img>
                                </b-aspect>
                              </template>

                              <template v-else>  
                                <b-aspect 
                                  :aspect="(dish.complex && dish.complexType===1)?'4':'3:2'" 
                                  class="dish-banner rounded-top">
                                  <b-img @click="showDish(dish.rn)" role="button" :src="(dish.photoExists)?dish.photoURL:((dish.complex && (dish.complexType===2 || dish.complexType===1))?'/img/basket_'+dish.complexType+'.png':'/img/empty.png')" class="w-100"   style="position:absolute;left:0"></b-img>
                                </b-aspect>
                              </template>  
                              <!---->
                              <div class="dish-mnemo mb-2" :class="(dish.complex && dish.complexType===1 && settings.hide_fixed_complex_banner)?'mt-3 font-weight-bold':'mt-2'">{{dish.mnemo}}</div>
                              <!---->
                              <template v-if="dish.complex && dish.complexType===1">
                                <div 
                                  class="d-block d-md-none mb-3"
                                  @click="showDish(dish.rn)"
                                >
                                  <template v-for="cm of dishes[dish.rn].complexParts">
                                    <div v-if="dishes_complex[cm.menuRN]" :key="cm.menuRN" class="mini_list_parts ml-2">
                                      <b-icon icon="check" class="mr-1"></b-icon>{{(dishes_complex[cm.menuRN]).mnemo}}
                                    </div>
                                  </template>
                                </div>  
                                <div 
                                  class="d-none d-md-block dish-moreinfo"
                                  @click="showDish(dish.rn)"
                                  role="button"
                                >
                                  <b-collapse :id="'complex-parts-'+ dish.rn" :visible="settings.hide_fixed_complex_banner">
                                  <template v-for="(cm,idx) of dishes[dish.rn].complexParts">
                                    <div v-if="dishes_complex[cm.menuRN] && idx < 3" :key="cm.menuRN" class="mini_list_parts ml-2 small">
                                      <b-icon icon="check" class="mr-1"></b-icon>{{(dishes_complex[cm.menuRN]).mnemo}}
                                    </div>
                                    <div v-if="idx === 3" :key="cm.menuRN" class="text-center m-0 p-0">
                                      <b-iconstack >
                                        <b-icon stacked icon="record-fill" variant="dark" scale="0.3"></b-icon>
                                        <b-icon stacked icon="record-fill" variant="dark" shift-h="10" scale="0.3"></b-icon>
                                        <b-icon stacked icon="record-fill" variant="dark" shift-h="-10" scale="0.3"></b-icon>
                                      </b-iconstack>
                                    </div>
                                  </template>
                                  </b-collapse>
                                </div>  
                              </template>  
                              <template v-else>
                                <div class="d-none d-md-block dish-moreinfo"></div>
                              </template>  
                              <!---->
                              <div class="dish_card_text_bottom">
                                <div class="price-outcome" v-if="dish.outcome">{{dish.outcome}}</div>
                                <div class="price" v-if="!salepoint.hidePrice">
                                  <span class="price-uah">{{dish.priceInt}}</span><span class="price-kop">{{dish.priceKop}}</span><span class="price-hrn">грн</span>
                                </div>

                                <div @click="addCart(dish.rn)" class="add_cart_item" role="button">
                                  <b-iconstack class="h3" v-if="dish.busy_button_state && dish.busy_button_state===1">
                                    <b-icon stacked icon="cart-fill" variant="white"></b-icon>
                                    <b-icon stacked icon="cart" variant="secondary"></b-icon>
                                    <b-icon stacked icon="arrow-repeat" scale="0.4" variant="dark" animation="spin"></b-icon>
                                  </b-iconstack>
                                  <b-icon v-else icon="cart-plus" variant="dark" class="h3"></b-icon>
                                  <div class="item_quant" v-show="dish.quant_cart > 0">{{ dish.quant_cart }}</div>  
                                </div>
                              </div>
                              <!---->  

                              <b-iconstack  
                                @click="setCartQuant(dish.rn, 0)" 
                                role="button" 
                                v-show="dish.quant_cart > 0" 
                                class="h4 ico-remove-item"
                                title="Прибрати з кошику"
                              >
                                <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                                <b-icon stacked icon="circle" variant="dark"></b-icon>
                                <b-icon stacked icon="trash" variant="dark" scale="0.6"></b-icon>
                              </b-iconstack>

                              <div v-if="dish.specmarkRNs.length>0" :class="(!dish.complex)?'dish_smarks':'dish_complex_smarks'">
                                <template v-for="(smrk,index3) of dish.specmarkRNs">
                                  <b-badge  :key="index3" v-if="specmarks[smrk]!=null" :class="'mark_'+(specmarks[smrk]).name">
                                    {{(specmarks[smrk]).name}}
                                  </b-badge>
                                </template>  
                              </div>

                              <div v-if="dish.complex" class="dish_complex">
                                <b-iconstack font-scale="1" :title="(dish.complexType===1)?'Комплексна страва':'Довільна комплексна страва'">
                                  <b-icon
                                    stacked
                                    icon="circle-fill"
                                    variant="white"
                                    scale="1.5"
                                  ></b-icon>
                                  <b-icon
                                    stacked
                                    icon="circle"
                                    variant="bruvis"
                                    scale="1.5"
                                  ></b-icon>
                                  <b-icon
                                    stacked
                                    icon="stack"
                                    variant="bruvis"
                                  ></b-icon>
                                  <template v-if="dish.complexType===2">
                                    <b-icon
                                      stacked icon="gear-fill"
                                      scale="1" variant="victor"
                                      shift-h=8 shift-v=8 ></b-icon>
                                    <b-icon
                                      stacked icon="gear"
                                      scale="1" variant="bruvis"
                                      shift-h=8 shift-v=8></b-icon>
                                  </template>    
                                </b-iconstack>  
                              </div>

                            </div>  
<!--
                            <b-iconstack  @click="setCartQuant(dish.rn, 0)" role="button" v-show="dish.quant_cart > 0" class="h4 ico-remove-item">
                              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
                              <b-icon stacked icon="circle" variant="dark"></b-icon>
                              <b-icon stacked icon="trash" variant="dark" scale="0.6"></b-icon>
                            </b-iconstack>

                           

                            
-->                            
                          </b-aspect>
                          <!-- Variant 3 end -->



                        </div>


                      </b-col>  
                    </template>
                    </b-row>  
                  </b-tab>
                </template>
                <template v-else>
                  <b-tab title="Меню не сформовано">  
                      <div class="alert alert-info">На цей час меню не сформовано</div>
                  </b-tab>
                </template>      
                <template v-slot:tabs-end v-if="html_left_block">
                  <div class="d-none d-md-block my-2 w-100" v-html="html_left_block"></div>
                </template>  
              </b-tabs>
              <div v-else class="p-2 h5">
                <!--h6 class="alert alert-danger m-0 mb-3" v-if="alertmessage">{{ alertmessage }}</h6-->
                <b-form-select
                    v-if="salepoints.length > 1"
                    v-model="salepoint_menu_rn"
                    :options="salepoints"
                    class="mb-2 shadow-none"
                    value-field="rn"
                    text-field="posname"
                    @change = "changeSalePoint(salepoint_menu_rn)"
                />  

                <div class="alert alert-info">На цей час меню не сформовано</div>
              </div>
            </b-card>
          </b-row>
          <!--MAIN WINDOW END-------------------------->          
        </b-col>
<!---------------------------------------------------------------------------------------------->        
      </b-row>
    </b-container>
    </b-overlay>

    <div v-if="html_footer">
      <div class="d-none d-md-block my-2 w-100" v-html="html_footer"></div>
    </div>

    <small class="text-white">{{cart_sum}}-{{cart_count}}</small>
    <!--/b-overlay-->

<!--  MODALS  -->

<!--LOGIN-------------------------------------------------------------------------->    

          <b-modal 
            :id="(banners)?'login-modal-wide':'login-modal'"
            hide-header-close 
            hide-footer
            hide-header 
            centered 
            v-model="needlogin" 
            @hidden="busy = false" 
            class="p-0"
            :no-close-on-backdrop="true"
            >
              <div style="" class="login-modal-body" >
                <!-------------------------------------------------------------------------->
                <template v-if="!banners">
                  <div v-bind:class="{ error: emptyFields }">
                    <div class="form-group login-form">
                      <b-overlay
                        :show="busy"
                        rounded 
                        variant="white"
                        blur="2px" 
                        opacity="0.6"
                        spinner-variant="bruvis"
                        class="d-inline-block w-100"
                      >
                        <input v-model="nameLogin" type="text" class="form-control my-2" placeholder="Логін" required>
                        <input v-model="passwordLogin" type="password" class="form-control my-2" placeholder="Пароль" required>
                        <div v-if="error_login_msg" class="text-danger my-2">{{error_login_msg}}</div>
                        <b-button class="mt-3 shadow-none" block @click="doLogin" :disabled="busy || nameLogin==='' || passwordLogin===''">Увійти</b-button>
                      </b-overlay>  
                    </div>
                  </div>
                  <div class="small text-center powered">
                    Powered by <a target="_blank" href="https://jsolutions.ua/ua/avtomatizatciya-korporativnogo-pitaniya"><b-img src="/img/logo_jsol.png" width="71"></b-img></a>
                  </div> 
                </template>
                <!-------------------------------------------------------------------------->
                <template v-else>
                  <div v-bind:class="{ error: emptyFields }" class="banner-group">
                    <div id="row1">
                      <template v-if="banners['TOP LEFT'].banners_grid_mode===2">

                        <div class="banner-cell" :style="(banners['TOP LEFT'])?'background-image:url(/img/cache/banners/'+ banners['TOP LEFT'].imageRN +'.png)':''">
                          <a :href="banners['TOP LEFT'].url" v-if="banners['TOP LEFT'].url" class="banner__link"></a>
                        </div>
                        <div class="banner-cell" :style="(banners['TOP MIDDLE'])?'background-image:url(/img/cache/banners/'+ banners['TOP MIDDLE'].imageRN +'.png)':''">
                          <a :href="banners['TOP MIDDLE'].url" v-if="banners['TOP MIDDLE'].url" class="banner__link"></a>
                        </div>
                        <div class="banner-cell" :style="(banners['TOP RIGHT'])?'background-image:url(/img/cache/banners/'+ banners['TOP LEFT'].imageRN +'.png)':''">
                          <a :href="banners['TOP RIGHT'].url" v-if="banners['TOP RIGHT'].url" class="banner__link"></a>
                        </div>
                      </template>

                      <template v-if="banners['TOP LEFT'].banners_grid_mode===1">
                        <div class="banner-cell">
                          <template v-if="banners['TOP LEFT']">
                            <a :href="banners['TOP LEFT'].url" v-if="banners['TOP LEFT'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['TOP LEFT'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['TOP LEFT'].imageRN+'.png'">  
                          </template>  
                        </div>
                        <div class="banner-cell">
                          <template v-if="banners['TOP MIDDLE']">
                            <a :href="banners['TOP MIDDLE'].url" v-if="banners['TOP MIDDLE'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['TOP MIDDLE'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['TOP MIDDLE'].imageRN+'.png'">  
                          </template>  
                        </div>
                        <div class="banner-cell">
                          <template v-if="banners['TOP RIGHT']">
                            <a :href="banners['TOP RIGHT'].url" v-if="banners['TOP RIGHT'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['TOP RIGHT'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['TOP RIGHT'].imageRN+'.png'">  
                          </template>  
                        </div>
                      </template>

                    </div>
                    <div id="row2">

                      <template v-if="banners['MIDDLE LEFT'].banners_grid_mode===1">
                        <div class="banner-cell d-none d-md-block">
                          <template v-if="banners['MIDDLE LEFT']">
                            <a :href="banners['MIDDLE LEFT'].url" v-if="banners['MIDDLE LEFT'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['MIDDLE LEFT'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['MIDDLE LEFT'].imageRN+'.png'">  
                          </template>  
                        </div>
                      </template>

                      <template v-if="banners['MIDDLE LEFT'].banners_grid_mode===2">  
                        <div class="banner-cell  d-none d-md-block" :style="(banners['MIDDLE LEFT'])?'background-image:url(/img/cache/banners/'+ banners['MIDDLE LEFT'].imageRN +'.png)':''">
                          <a :href="banners['MIDDLE LEFT'].url" v-if="banners['MIDDLE LEFT'].url" class="banner__link"></a>
                        </div>
                      </template>  

                      <div class="form-group login-form banner-cell">
                        <b-card header-tag="header">
                          <template #header>
                            <h6 class="mb-0 text-center">Авторизація</h6>
                          </template>
                          <b-overlay
                            :show="busy"
                            rounded 
                            variant="white"
                            blur="2px" 
                            opacity="0.6"
                            spinner-variant="bruvis"
                            class="d-inline-block w-100"
                          >
                            <input v-model="nameLogin" type="text" class="form-control my-2" placeholder="Логін" required>
                            <input v-model="passwordLogin" type="password" class="form-control my-2" placeholder="Пароль" required>
                            <div v-if="error_login_msg" class="text-danger my-2">{{error_login_msg}}</div>
                            <b-button class="mt-3 shadow-none" block @click="doLogin" :disabled="busy || nameLogin==='' || passwordLogin===''">Увійти</b-button>
                          </b-overlay>  
                        </b-card>
                        <div class="small text-center powered m-2">
                          Powered by <a target="_blank" href="https://jsolutions.ua/ua/avtomatizatciya-korporativnogo-pitaniya"><b-img src="/img/logo_jsol.png" width="71"></b-img></a>
                        </div>   
                      </div>

                      <template v-if="banners['MIDDLE RIGHT'].banners_grid_mode===1">
                        <div class="banner-cell d-none d-md-block">
                          <template v-if="banners['MIDDLE RIGHT']">
                            <a :href="banners['MIDDLE RIGHT'].url" v-if="banners['MIDDLE RIGHT'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['MIDDLE RIGHT'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['MIDDLE RIGHT'].imageRN+'.png'">  
                          </template>  
                        </div>
                      </template>  

                      <template v-if="banners['MIDDLE RIGHT'].banners_grid_mode===2">
                        <div class="banner-cell  d-none d-md-block" :style="(banners['MIDDLE RIGHT'])?'background-image:url(/img/cache/banners/'+ banners['MIDDLE RIGHT'].imageRN +'.png)':''">
                          <a :href="banners['MIDDLE RIGHT'].url" v-if="banners['MIDDLE RIGHT'].url" class="banner__link"></a>
                        </div>
                      </template>  

                    </div>
                    <div id="row3">

                      <template v-if="banners['BOTTOM MIDDLE'].banners_grid_mode===1">
                        <div>
                          <template v-if="banners['BOTTOM MIDDLE']">
                            <a :href="banners['BOTTOM MIDDLE'].url" v-if="banners['BOTTOM MIDDLE'].url">  
                              <img class="banner-img" :src="'/img/cache/banners/'+banners['BOTTOM MIDDLE'].imageRN+'.png'">
                            </a>
                            <img v-else class="banner-img" :src="'/img/cache/banners/'+banners['BOTTOM MIDDLE'].imageRN+'.png'">  
                          </template>  
                        </div>
                      </template>  

                      <template v-if="banners['BOTTOM MIDDLE'].banners_grid_mode===2">
                        <div class="banner-row" :style="(banners['BOTTOM MIDDLE'])?'background-image:url(/img/cache/banners/'+ banners['BOTTOM MIDDLE'].imageRN +'.png)':''">
                          <a :href="banners['BOTTOM MIDDLE'].url" v-if="banners['BOTTOM MIDDLE'].url" class="banner__link"></a>
                        </div>
                      </template>

                    </div>

                  </div>
                  
                </template>



              </div>
          </b-modal>

          <!--b-modal 
            id="login-modal-wide"
            hide-header-close 
            hide-footer
            hide-header 
            centered 
            v-model="needlogin" 
            @hidden="busy = false" 
            class="modal-fullscreen"
            :no-close-on-backdrop="true"
            >
              <div style="" class="login-modal-body" >
                <div v-bind:class="{ error: emptyFields }">
                  <div class="form-group login-form">
                    <b-overlay
                      :show="busy"
                      rounded 
                      variant="white"
                      blur="2px" 
                      opacity="0.6"
                      spinner-variant="bruvis"
                      class="d-inline-block w-100"
                    >
                      <input v-model="nameLogin" type="text" class="form-control my-2" placeholder="Логін" required>
                      <input v-model="passwordLogin" type="password" class="form-control my-2" placeholder="Пароль" required>
                      <div v-if="error_login_msg" class="text-danger my-2">{{error_login_msg}}</div>
                      <b-button class="mt-3 shadow-none" block @click="doLogin" :disabled="busy || nameLogin==='' || passwordLogin===''">Увійти</b-button>
                    </b-overlay>  
                  </div>
                </div>
                <div class="small text-center powered">
                  Powered by <a target="_blank" href="https://jsolutions.ua/ua/avtomatizatciya-korporativnogo-pitaniya"><b-img src="/img/logo_jsol.png" width="71"></b-img></a>
                </div> 
              </div>
          </b-modal-->



<!-- Dish target ----------------------------------------------------------              size="sm" --->
            <b-modal id="dish-target-modal" 
              :hide-header="dishes[target_dish] && !dishes[target_dish].complex"
              hide-header-close 
              centered 
              scrollable 
              ok-only
              size = "xl"
              @hidden = "target_dish = null"
              @shown  = "target_dish_quant = (dishes[target_dish]).quant_cart"              
            >
              <template #modal-title v-if="dishes[target_dish] && dishes[target_dish].complexType===1">
                <b-iconstack font-scale="1" class="mx-2">
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="white"
                    scale="1.5"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="circle"
                    variant="bruvis"
                    scale="1.5"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="stack"
                    variant="bruvis"
                  ></b-icon>
                </b-iconstack><span class="p-2 h5 mr-auto">{{(dishes[target_dish]).mnemo}}</span>
              </template>
              
              <!-- hgidden -targetComplex=[]-->
              <b-card
                v-if="target_dish !== null && dishes[target_dish] !== null"
                class="mb-2 border-white"
              >
                <!--NO COMPLEX---------------------------------------------------------------------------------->
                <b-row no-gutters v-if="dishes[target_dish] && !dishes[target_dish].complex">
                  <b-col md="6">
                    <template v-if="(dishes[target_dish]).photoURL">
                      <b-card-img :src='(dishes[target_dish]).photoURL.replace("thumb.jpg","middle.jpg")' alt="Image" class="rounded-0"></b-card-img>
                    </template>  
                    <template v-else>
                      <b-card-img src='/img/empty.png' alt="Image" class="rounded-0"></b-card-img>
                    </template>  

                  </b-col>
                  <b-col md="6">
                    <b-card-body 
                      :title="(dishes[target_dish]).mnemo"
                      :sub-title="(!salepoint.hideIngredients)?(dishes[target_dish]).info:null"
                    >
                      <b-card-text>
                        
                        <div>
                          {{(dishes[target_dish]).price | toCurrency}}
                          <template v-if="(dishes[target_dish]).outcome != null">
                            &nbsp;/&nbsp;{{(dishes[target_dish]).outcome}}
                          </template>  
                        </div>

                        <template v-if="(dishes[target_dish]).complex">
                          <div v-for="cmpl in (dishes[target_dish]).complexParts" :key="cmpl.menuRN">
                            <div>{{cmpl.mnemo}} <template v-if="cmpl.quant>1">(x {{cmpl.quant}})</template></div>
                          </div>    
                        </template>

                        <div v-if="!(dishes[target_dish]).energyParamList && dishes[target_dish] && (dishes[target_dish]).energy">
                          <b-icon icon="lightning-charge" class="mr-2"></b-icon>{{(dishes[target_dish]).energy}} кКал / 100 г.
                        </div>

                        <h5 v-if="!salepoint.hideEnergyParam && !(dishes[target_dish]).energyParamList && dishes_complex[target_dish] && (dishes_complex[target_dish]).energy">
                            <b-badge variant="warning">
                              <b-iconstack font-scale="1" class="mr-1">
                                <b-icon
                                  stacked icon="lightning-charge-fill"
                                  variant="secondary"
                                ></b-icon>
                                <b-icon
                                  stacked icon="lightning-charge"
                                  variant="bruvis"
                                ></b-icon>
                              </b-iconstack>  
                          </b-badge>
                        </h5>  

                        <div class="display:block;"><!--margin:0 auto;-->
                        <GChart
                          type="PieChart"
                          :settings="{ packages: ['corechart'] }"
                          :data="(dishes[target_dish]).energyParamList"
                          v-if="(dishes[target_dish]).energyParamList"
                          :options='{ 
                                    "is3D":"true", 
                                    "title":((dishes[target_dish]).energy)?"Енергетична цінність "+(dishes[target_dish]).energy+" кКал":"БЖУ",
                                    "width":"400",
                                    "height":"300"
                                    }'
                                    class='gchart'
                        />
                        </div>
                        
                        <div v-if="(dishes[target_dish]).specmarkRNs.length>0" class="target-smrk">
                          <b-badge v-for="(smrk,index) of (dishes[target_dish]).specmarkRNs" :key="index">
                            <template v-if="specmarks[smrk]!=null">{{(specmarks[smrk]).name}}</template>
                          </b-badge>
                        </div> 

                        <div v-if="(dishes[target_dish]).debug">
                          <pre style="font-size:11px;">
                            {{(dishes[target_dish]).debug}}
                          </pre>  
                        </div>
                      
                      </b-card-text>
                    </b-card-body>  
                  </b-col>
                </b-row>
                <!-----COMPLEX-TARGET-BEGIN------>
                <template v-else-if="dishes[target_dish].complexType===1">
                  <!--b-overlay
                    :show="complexbusy"
                    rounded
                    variant="white"
                    blur="2px" 
                    opacity="0.9"
                    spinner-variant="bruvis"
                    class="d-inline-block w-100"
                    style="inset:0px;"
                  -->
                    <div>
                      <!--b-row no-gutters class="mb-2">  
                        <b-col md="12" v-if="dishes[target_dish]"-->
                          <!--b-card-body 
                            :title="(dishes[target_dish]).mnemo"
                          >
                          </b-card-body--><!--:sub-title="(dishes[target_dish]).info"-->
                        <!--/b-col>
                      </b-row-->  <!--!complexbusy-->
                      <b-row no-gutters v-for="cm of dishes[target_dish].complexParts" :key="cm.menuRN" class="mb-2">
                        <b-col md="4">
                          <!--template v-if="dishes[cm.menuRN] && (dishes[cm.menuRN]).photoURL">
                            <b-card-img :src="(dishes[cm.menuRN]).photoURL" :alt="(dishes[cm.menuRN]).mnemo" class="rounded-0"></b-card-img>
                          </template--> 
                          <template v-if="dishes_complex[cm.menuRN] && (dishes_complex[cm.menuRN]).photoURL">
                            <b-card-img :src="(dishes_complex[cm.menuRN]).photoURL" :alt="(dishes_complex[cm.menuRN]).mnemo" class="target-banner"></b-card-img>
                          </template>  
                          <template v-else>
                            <b-card-img src='/img/empty.png' alt="Image" class="rounded-0"></b-card-img>
                          </template>  
                        </b-col>
                        <!--b-col md="8" v-if="dishes[cm.menuRN]">
                          <b-card-body 
                            :title="(cm.quant>1)?(dishes[cm.menuRN]).mnemo+' (x '+ cm.quant+')':(dishes[cm.menuRN]).mnemo"
                            :sub-title="(dishes[cm.menuRN]).info"
                          >
                            <b-card-text>
                              <div>
                                <template v-if="(dishes[cm.menuRN]).outcome != null">
                                  {{(dishes[cm.menuRN]).outcome}}
                                </template>  
                              </div>
                              <div v-if="(dishes[cm.menuRN]).specmarkRNs.length>0" class="target-smrk">
                                <b-badge v-for="(smrk,index) of (dishes[cm.menuRN]).specmarkRNs" :key="index" :class="'mark_'+(specmarks[smrk]).name">
                                  <template v-if="specmarks[smrk]!=null">{{(specmarks[smrk]).name}}</template>
                                </b-badge>
                              </div> 


                            </b-card-text>
                          </b-card-body>
                        </b-col-->
                        <b-col md="8" v-if="dishes_complex[cm.menuRN]">
                          <b-card-body 
                            :title="(cm.quant>1)?(dishes_complex[cm.menuRN]).mnemo+' (x '+ cm.quant+')':(dishes_complex[cm.menuRN]).mnemo"
                            :sub-title="(!salepoint.hideIngredients)?(dishes_complex[cm.menuRN]).info:null"
                          >
                            <b-card-text>
                              <div>
                                <template v-if="(dishes_complex[cm.menuRN]).outcome != null">
                                  {{(dishes_complex[cm.menuRN]).outcome}}
                                </template>  
                              </div>
                              <h5 v-if="!salepoint.hideEnergyParam && (dishes_complex[cm.menuRN]).energy">
                                <b-badge variant="warning">
                                  <b-iconstack font-scale="1" class="mr-1">
                                    <b-icon
                                      stacked icon="lightning-charge-fill"
                                      variant="secondary"
                                    ></b-icon>
                                    <b-icon
                                      stacked icon="lightning-charge"
                                      variant="bruvis"
                                    ></b-icon>
                                  </b-iconstack>  
                                  <!--b-icon icon="lightning-charge" class="mr-2"></b-icon-->{{(dishes_complex[cm.menuRN]).energy}} кКал / 100 г.
                                </b-badge>
                              </h5>  
                              <div v-if="(dishes_complex[cm.menuRN]).specmarkRNs.length>0" class="target-smrk">
                                <b-badge v-for="(smrk,index) of (dishes_complex[cm.menuRN]).specmarkRNs" :key="index" :class="'mark_'+(specmarks[smrk]).name">
                                  <template v-if="specmarks[smrk]!=null">{{(specmarks[smrk]).name}}</template>
                                </b-badge>
                              </div> 


                            </b-card-text>
                          </b-card-body>
                        </b-col>
                      </b-row>
                    </div>
                    <pre v-if="debugmode">{{this.dishes_complex}}</pre>
                  <!--/b-overlay-->  
                </template> 
                <!--Free complex------------------------------------------------------------->
                <!--
                <template v-else-if="dishes[target_dish].complexType===2">
                  <FreeComplex
                    :complex_dish = "dishes[target_dish]"
                    @needlogin = "needlogin = true"
                    :specmarks="specmarks"
                    :debugmode="debugmode" 
                  />
                </template>  
                -->
                <!-----COMPLEX-TARGET-END------>    
              </b-card>

              <template #modal-footer="{ ok, cancel }">
                <template v-if="!(salepoint.onlyOnePos && (dishes[target_dish]).complex)">
                  <b-form-spinbutton 
                    v-if="!(salepoint.onlyOnePos && (dishes[target_dish]).complex)"
                    v-model="target_dish_quant"
                    :step="(dishes[target_dish]).minportion" 
                    min="0" max="100"
                    @change="target_dish_quant_changed = true" 
                    inline></b-form-spinbutton>
                  <b-button 
                    variant="success" 
                    @click="setCartQuant((dishes[target_dish]).rn, target_dish_quant),ok()"
                    :disabled = "target_dish_quant == (dishes[target_dish]).quant_cart"
                  >
                  <b-icon icon="cart-plus" variant="light" class="h4 m-0"></b-icon>
                  </b-button>
                  <b-button variant="bruvis" @click="cancel()">
                    Закрити
                  </b-button>
                </template>
                <template v-else>
                  <!-- режим огроаничения комплексов -->
                  <b-button 
                    variant="success" 
                    @click="setCartQuant((dishes[target_dish]).rn, 1),ok()"
                    :disabled = "complexlimit && complexlimit[ondate] && complexlimit[ondate][mealtime]"
                  >
                  <b-icon icon="cart-plus" variant="light" class="h4 m-0"></b-icon>
                  </b-button>
                  <b-button variant="bruvis" @click="cancel()">
                    Закрити
                  </b-button>

                </template>    
              </template>      
            </b-modal>
<!--Dish target END----->

<!-- Freecomplex target ---------------------------------------------->
            <b-modal id="target-freecomplex-modal" 
              hide-header-
              hide-header-close 
              centered 
              scrollable 
              ok-only
              size = "xl"
              @hidden = "target_dish = null"
            >
              <template #modal-title v-if="target_dish !== null && dishes[target_dish] !== null">
                <b-iconstack font-scale="1" class="mx-2">
                  <b-icon
                    stacked icon="circle-fill"
                    variant="white" scale="1.5"
                  ></b-icon>
                  <b-icon
                    stacked icon="circle"
                    variant="bruvis" scale="1.5"
                  ></b-icon>
                  <b-icon
                    stacked icon="stack"
                    variant="bruvis" 
                  ></b-icon>
                  <b-icon
                    stacked icon="gear-fill"
                    scale="1" variant="victor"
                    shift-h=8 shift-v=8 ></b-icon>
                  <b-icon
                    stacked icon="gear"
                    scale="1" variant="bruvis"
                    shift-h=8 shift-v=8></b-icon>
                </b-iconstack><span class="p-2 h5 mr-auto">{{(dishes[target_dish]).mnemo}}</span>
              </template>

              <b-card
                v-if="target_dish !== null && dishes[target_dish] !== null"
                class="mb-2 border-white"
              >
                <template v-if="dishes[target_dish].complexType===2">
                  <template v-if="settings && settings.enable_free_complex">
                    <FreeComplex
                      :complex_dish = "dishes[target_dish]"
                      @needlogin = "needlogin = true"
                      :specmarks="specmarks"
                      :debugmode="debugmode" 
                    />
                  </template>  
                  <template v-else>
                    <div class="alert alert-info">Вільні комплекси не підтримуються</div>
                  </template>  
                </template>  
              </b-card>

              <template #modal-footer="{ ok, cancel }">
                <template v-if="!(salepoint.onlyOnePos && (dishes[target_dish]).complex)">
                  
                  <b-button 
                    variant="success" 
                    @click="setCartQuant((dishes[target_dish]).rn, target_dish_quant),ok()"
                    :disabled = "target_dish_quant == (dishes[target_dish]).quant_cart"
                  >
                  <b-icon icon="cart-plus" variant="light" class="h4 m-0"></b-icon>
                  </b-button>
                  <b-button variant="bruvis" @click="cancel()">
                    Закрити
                  </b-button>
                </template>
                <template v-else>
                  <!-- режим огроаничения комплексов -->
                  <b-button 
                    variant="success" 
                    @click="setCartQuant((dishes[target_dish]).rn, 1),ok()"
                    :disabled = "complexlimit && complexlimit[ondate] && complexlimit[ondate][mealtime]"
                  >
                  <b-icon icon="cart-plus" variant="light" class="h4 m-0"></b-icon>
                  </b-button>
                  <b-button variant="bruvis" @click="cancel()">
                    Закрити
                  </b-button>

                </template>    
              </template>      
            </b-modal>
<!--Dish target Freecomplex END----->

<!--BASKET BEGIN--------------------------------------------------------------------->
          <b-modal id="basket-modal2" hide-header-close- centered scrollable size="lg" :no-close-on-backdrop="true">
            <template #modal-title>
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Замовлення на {{(mealtimes[mealtime]).mnemo}} ({{new Date(ondate)  | dateFormat('dddd D MMMM')}})</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>

            <!--table class="table table-striped">
              <tbody>
                <tr v-for="ci of cart" :key="ci.menu_rn">
                  <td class="wrdwrp align-top">{{cartItems[ci.menu_rn]['mnemo']}}</td>
                  <td class="align-top"><b-icon icon="trash" @click="setCartQuant(ci.menu_rn, 0)" title="Remove" variant="" class="h4 m-1" role="button"></b-icon></td>
                  <td class="align-top"><b-form-spinbutton 
                    :id="cart.menu_rn+'_quant'" 
                    v-model="ci.quant" 
                    @change="setCartQuant(ci.menu_rn, $event)"
                    min="1" max="100" size="sm" 
                    inline></b-form-spinbutton>
                  </td>
                  <td class="p-3 text-right align-top" :title="ci.quant+' х '+cartItems[ci.menu_rn]['price']">{{ ci.quant * Math.round(cartItems[ci.menu_rn]['price'] * 100) / 100}}&nbsp;<span>&#8372;</span></td>
                </tr>
                <tr>
                  <td></td>  
                  <td></td>  
                  <th colspan="2" class="text-right text-secondary">Разом: <span class="text-body">{{cart_sum}}</span>&nbsp;&#8372;</th>  
                </tr>
              </tbody>  
            </table-->

            <b-overlay
              :show="busy"
              rounded 
              variant="white"
              blur="2px" 
              opacity="0.6"
              spinner-variant="bruvis"
              class="d-inline-block w-100"
            >

              <div class="basket-rows">
                  <div v-for="ci of cart" :key="ci.menu_rn"  class="basket-row">
                    <div class="basket-cell basket-cell1 wrdwrp">
                      <b-icon icon="pencil-square" font-scale="1.5" class="pr-2" title="Додати примітки до страви" role="button" @click="openRamark(ci.menu_rn)"></b-icon>
                      {{cartItems[ci.menu_rn]['mnemo']}}

                    </div>
                    <div class="basket-cell basket-cell12 align-top">
                      <b-icon icon="trash" @click="setCartQuant(ci.menu_rn, 0)" title="Remove" variant="" class="h4 m-0" role="button"></b-icon>
                    </div>
                    <div class="basket-line-break"></div>
                    <div class="basket-cell basket-cell3">  

                      <template v-if="!(salepoint.onlyOnePos && (dishes[ci.menu_rn]).complex)"> 
                        <template v-if="(dishes[ci.menu_rn]).minportion===1">
                          <SpinbuttonManual
                            :id="cart.menu_rn+'_quant'" 
                            :value="ci.quant"
                            @input="setCartQuant(ci.menu_rn, $event)"
                          />
                        </template>  
                        <template v-else>
                          <b-form-spinbutton 
                            :id="cart.menu_rn+'_quant'" 
                            v-model="ci.quant"
                            :step="(dishes[ci.menu_rn]).minportion"  
                            @change="setCartQuant(ci.menu_rn, $event)"
                            :min="(dishes[ci.menu_rn]).minportion" max="100" size="sm" 
                            inline>
                          </b-form-spinbutton>
                        </template>
                      </template>

                    </div>
                    <template v-if="salepoint && !salepoint.hidePrice">
                      <div class="basket-cell basket-cell4 text-right" :title="ci.quant+' х '+cartItems[ci.menu_rn]['price']">{{ ci.quant * Math.round(cartItems[ci.menu_rn]['price'] * 100) / 100}}&nbsp;<span>&#8372;</span></div>
                    </template>  
                  </div>
              </div>
              <template v-if="salepoint && !salepoint.hidePrice">
                <div v-if="cart.length>1" class="w-100 pt-2 mt-2">
                  <div class="text-right text-secondary">Разом: <span class="text-body">{{cart_sum}}</span>&nbsp;&#8372;</div>  
                </div>  
              </template>  
            </b-overlay>

            <template #modal-footer> <!--="{ reservOrder, payOrder()}-->
              <!--span class="mr-auto text-secondary font-weight-bold h5">До сплати: <span class="text-body">{{cart_sum}}</span>&nbsp;&#8372;</span-->
              <b-button variant="success" @click="reservOrder()" :disabled="cart.length === 0">
                <b-icon icon="bag-check"></b-icon> Замовити
              </b-button>
              <!--b-button variant="danger" @click="payOrder()">
                <b-icon icon="credit-card"></b-icon> Оплатити {{cart_sum}}&nbsp;&#8372;
              </b-button-->
              <!--b-button size="sm" variant="outline-secondary" @click="close()">
                Відміна
              </b-button-->
          </template>
          </b-modal>

<!--BASKET END--------------------------------------------------------------------->

<!--ORDERS BEGIN--------------------------------------------------------------------->
          <b-modal 
            id="orders-modal" 
            hide-header-close- 
            centered 
            scrollable 
            size="md" 
            :no-close-on-backdrop="true"
            @shown = "globalbusy=false,widget_is_visible = false,select_pay_method_visible=false,selectAllOff(),selectAll()"
          >
            <!-- //eslint-disable-next-line -->
            <template #modal-title v-if="salepoint && salepoint.hidePrice">
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Замовлення</span>
            </template>
            <template #modal-title v-else-if="!widget_is_visible && !select_pay_method_visible">
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Неоплачені замовлення</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>
            <template #modal-title v-else>
              <b-icon icon="credit-card" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплата замовлень</span>
              <!--b-icon icon="x-square" class="rounded p-0" font-scale="1.5" role="button" @click="close()"></b-icon-->
            </template>

            <b-overlay
              :show="ordersbusy"
              rounded
              variant="white"
              blur="2px" 
              opacity="0.9"
              spinner-variant="bruvis"
              class="d-inline-block w-100"
              style="inset:0px;"
            >
            <b-form-checkbox-group
                  id="checkbox-group-2pay"
                  v-model="selectedTopay"
                  name="selected2pay"
                  @change = "recalcSumToPay"
                  switches
                  class="bruvis"
            >
            <!--code>{{selectedTopay}}</code>
            <code>{{allSelected}}</code-->
            
            <table 
              class="table orders-table" 
              sticky-header="true" 
              v-if="salepoint && Object.keys(zakazy).length > 0"
              v-show="!widget_is_visible && !select_pay_method_visible"
            >
              <thead>
                  <th class="align-bottom border-top-0 border-bottom-0" width="1"></th>
                  <th class="align-bottom border-top-0 border-bottom-0" width="500"><template v-if="!salepoint.hidePrice">Час</template></th>
                  <th class="align-bottom border-top-0 border-bottom-0 pl-3" colspan="2">
                    <template v-if="!salepoint.hidePrice">
                      <b-icon 
                        @click="selectAll()" 
                        :icon="(allSelected)?'toggle-on':'toggle-off'" 
                        class="mr-2"
                        role="button"
                        font-scale="1.4"
                      ></b-icon>Сума
                    </template>  
                  </th>
              </thead>
              <tbody>
                <template v-for="(zk,zkday) in zakazy">
                  <tr :key="zkday" class="bg-bruvis text-white">
                    <td colspan="3" class="pt-1 pb-1">{{ new Date(zkday) | dateFormat('dddd D MMMM')}}</td>
                    <td class="pt-1 pb-1 text-right">
                      <!--span v-if="limits && limits[zkday] && (limits[zkday]).balancePerDay>0 && limits[zkday].dayLimit>0" && dsum[zkday]>{{ dsum[zkday] }} / </span>
                      <span v-if="limits && limits[zkday] && (limits[zkday]).balancePerDay>0 && limits[zkday].dayLimit>0">{{(limits[zkday]).balancePerDay}}&nbsp;&#8372;</span>

                      <span v-if="limits && limits[zkday] && (limits[zkday]).balancePerWeek>0 && limits[zkday].weekLimit>0" && dsum[zkday]>{{ wsum[zkday] }} / </span>
                      <span v-if="limits && limits[zkday] && (limits[zkday]).balancePerDay>0 && limits[zkday].weekLimit>0">{{(limits[zkday]).balancePerDay}}&nbsp;&#8372;</span-->
                    </td>
                  </tr>  
                  <template v-for="z of zk">
                    <!--tr :key="z.rn" :class="( mealtimes[z.mealtime].timeTo < ((new Date()).getHours() + (new Date()).getMinutes()/100) && ((new Date(zkday)).getFullYear()+(new Date(zkday)).getMonth()+(new Date(zkday)).getDate()) == ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate()) )?'text-muted order-failed orders-specs-row':'orders-specs-row'"-->
                    <tr :key="z.rn" :class="( new Date(z.deadln) < new Date() )?'text-muted order-failed orders-specs-row':'orders-specs-row'">
                      <td 
                        v-b-toggle="'more'+z.rn"
                        :title="z['rn']"
                        class="td-active"
                      >
                        <b-icon icon="caret-down-fill" class="when-closed" font-scale="1.0"></b-icon>
                        <b-icon icon="caret-up-fill" class="when-open" font-scale="1.0"></b-icon>
                      </td>
                      <td class="align-middle td-active" 
                        role="button"
                        :title="(mealtimes[z.mealtime])?(mealtimes[z.mealtime]).timeFr + '-' + (mealtimes[z.mealtime]).timeTo:''"
                        v-b-toggle="'more'+z.rn"
                      ><template v-if="mealtimes[z.mealtime]">{{(mealtimes[z.mealtime]).mnemo}}</template></td> 
                      <!--totest
                         (limits && limits[zkday] && (limits[zkday]).dayLimit>0 && dsum[zkday] > (limits[zkday]).balancePerDay)?
                                  'Ви перевищили денний ліміт, що залишився: сумма '+dsum[zkday] + ', ліміт '+(limits[zkday]).balancePerDay:
                                  (limits && limits[zkday] && (limits[zkday]).weekLimit>0 && wsum[z.wnum] > limits['w'+z.wnum])?
                                    'Ви перевищили тижневий ліміт, що залишився: сумма '+wsum[z.wnum] + ', ліміт '+limits['w'+z.wnum]:''
                        -->
                      <td 
                        :class="((salepoint && !salepoint.creditCardPayment && salepoint.clubCardPayment && limits && limits[zkday] && (limits[zkday]).dayLimit>0 && dsum && dsum[zkday] && dsum[zkday] > (limits[zkday]).balancePerDay) || (salepoint && !salepoint.creditCardPayment && salepoint.clubCardPayment && limits && limits[zkday] && (limits[zkday]).weekLimit>0 && wsum && wsum[z.wnum] && wsum[z.wnum] > limits['w'+z.wnum]))?'bg-warning align-middle':'align-middle'"
                        v-b-popover.hover.bottomleft.v-victor="{ 
                          customClass: 'bg-victor text-black font-weight-bold popover', 
                          content :
                              (
                                   (salepoint && !salepoint.creditCardPayment && salepoint.clubCardPayment && limits && limits[zkday] && (limits[zkday]).dayLimit>0 && dsum && dsum[zkday] && dsum[zkday] > (limits[zkday]).balancePerDay)?
                                  'Ви перевищили денний ліміт, що залишився: сумма '+dsum[zkday] + ', ліміт '+(limits[zkday]).balancePerDay:
                                  (salepoint && !salepoint.creditCardPayment && salepoint.clubCardPayment && limits && limits[zkday] && (limits[zkday]).weekLimit>0 && wsum && wsum[z.wnum] && wsum[z.wnum] > limits['w'+z.wnum])?
                                    'Ви перевищили тижневий ліміт, що залишився: сумма '+wsum[z.wnum] + ', ліміт '+limits['w'+z.wnum]:''
                              )
                        }"
                      >
                        <!--v-if = "( (mealtimes[z.mealtime].timeTo > (new Date()).getHours() + (new Date()).getMinutes()/100 )) || ((new Date(zkday)).getFullYear()+(new Date(zkday)).getMonth()+(new Date(zkday)).getDate()) != ((new Date()).getFullYear()+(new Date()).getMonth()+(new Date()).getDate())"-->

                        <!-- 
                        (limits && limits[zkday] && (limits[zkday]).dayLimit>0 && dsum[zkday] > (limits[zkday]).balancePerDay && salepoint.combiPay===false)  || 
                                          (limits && limits[zkday] && (limits[zkday]).weekLimit>0 && wsum[z.wnum] > limits['w'+z.wnum] && salepoint.combiPay===false)"
                                          (limits && limits[zkday] && (limits[zkday]).monthLimit>0 && msum[z.mnum] > limits['m'+z.mnum] && salepoint.combiPay===false)
                        -->

                        <b-form-checkbox
                            v-if="new Date(z.deadln) > new Date() && !salepoint.hidePrice"
                              :disabled = "limits && limits[zkday] && !salepoint.combiPay && !salepoint.creditCardPayment &&
                                          (
                                            (
                                              dsum && dsum[zkday] && (limits[zkday]).dayLimit>0 && dsum[zkday] > (limits[zkday]).balancePerDay
                                            ) || 
                                            (
                                              wsum && wsum[z.wnum] && (limits[zkday]).weekLimit>0 && wsum[z.wnum] > limits['w'+z.wnum]
                                            ) || 
                                            (
                                              msum && msum[z.mnum] && (limits[zkday]).monthLimit>0 && msum[z.mnum] > limits['m'+z.mnum]
                                            )
                                          )"
                            :value="z.rn"
                            :id="'checkbox'+z.rn"
                            :ref="'checkbox'+z.rn"
                            @change="selectAllOff()"
                            role="button"
                        >{{z.sumToPay | toCurrency}}</b-form-checkbox>
                      </td>
                      <td width="1">
                        <div class="basket-icons">
                         <b-icon
                            v-if="z.rn.charAt(0) == 'o' && new Date(z.deadln) > new Date()"
                            icon="pencil-square" 
                            class="h4 m-1 mr-2"
                            variant="dark"
                            font-scale="1.3"
                            v-b-popover.hover.topright="'Редагувати замовлення'"
                            role="button"
                            @click="editOrder(z)"
                          ></b-icon>
                          <b-icon 
                            icon="trash" 
                            v-if="new Date(z.deadln) > new Date()"
                            @click="removeOrder(z.rn)" 
                            title="Видалити" 
                            class="h4 m-1" 
                            role="button"
                          />
                        </div>
                      </td>                   
                    </tr>
                    <tr :key="z.rn" class="p-0">
                      <td colspan="4" class="p-0 orders-menu-spec-td" >
                        <b-collapse :id="'more'+z.rn" class="mx-3 my-4 orders-menu-spec">
                          <div>
                            <div>
                              <template v-for="(spec,index2) of z.specs">
                                <li :key="index2">{{spec.menu}}
                                  <template v-if="spec.quant > 0"> (x{{spec.quant}})</template>
                                  <template v-if="spec.comments">
                                    <b-badge class="ml-1" variant="warning" v-if="spec.comments.split(';')[0]"> {{spec.comments.split(';')[0]}}</b-badge>
                                    <template v-if="spec.comments.split(';')[1]">
                                      <b-badge  class="ml-1" variant="warning" v-for="(cm,cmind) of spec.comments.split(';')[1].split(',')" :key=cmind>{{cm}}</b-badge>
                                    </template>  
                                  </template>  

                                </li>
                              </template>
                            </div>
                            <!--b-icon
                              v-if="z.rn.charAt(0) == 'o' && new Date(z.deadln) > new Date()"
                              icon="pencil-square" 
                              class="ico-edit-zakaz"
                              variant="dark"
                              font-scale="1.3"
                              v-b-popover.hover.topright="'Редагувати замовлення'"
                              role="button"
                              @click="editOrder(z)"
                            ></b-icon-->  
                          </div>

                          <div class="border-top mt-3 pt-2" v-if="salepoint.hidePrice">Граничний термін редагування : {{new Date(z.deadln) | dateFormat('D MMMM H:mm', dateFormatConfig)}}</div>
                          <div class="border-top mt-3 pt-2" v-else-if="new Date(z.deadln) > new Date()">Граничний термін оплати : {{new Date(z.deadln) | dateFormat('D MMMM H:mm', dateFormatConfig)}}</div>
                          <div class="alert alert-danger mt-2" v-else>Термін сплати замовлення сплив ({{new Date(z.deadln) | dateFormat('D.MM.YYYY H:mm', dateFormatConfig)}})</div>
                        </b-collapse>                        
                      </td>  
                    </tr>
                  </template>  
                </template>  
              </tbody>  
            </table>
            <div v-else class="text-center h4">
              Замовлень немає
            </div>  
            </b-form-checkbox-group>
            </b-overlay>

            <div v-if="select_pay_method_visible && !widget_is_visible" class="method-of-pay mb-4">
              <b-card-group deck>
                  <b-card role="button" @click="PayCreditCard(selectedTopay.join())">
                    <b-img src="/img/liqpay_0.png" fluid/>
                  </b-card>
                  <b-card role="button" v-if="combi_pay_method_visible" @click="preCombiPay(selectedTopay.join())">
                  <!--b-card role="button" v-if="combi_pay_method_visible" @click="confirm_combipay_visible = true,select_pay_method_visible=false"-->
                    <b-img src="/img/combipay.png" fluid/>
                  </b-card>
                  <b-card v-else role="button" @click="PayClubCard(selectedTopay.join())">
                    <b-row align-v="end">
                      <b-col>
                        <b-img src="/img/ballance2.png" fluid/>
                      </b-col>
                      <b-col align="center">
                        <h5>Клубна карта</h5>
                      </b-col>
                    </b-row>
                  </b-card>
                  
              </b-card-group>
            </div>

            <!--div v-if="confirm_combipay_visible && !widget_is_visible" class="method-of-pay mb-4">
              <b-card>
                <ConfirmCombi :sum_bankcard="this.sum_bankcard" :sum_clubcard="this.sum_clubcard"/>
                <b-button variant="bruvis" @click="PayCreditCard(selectedTopay.join(),'combi')">
                Оплатити
              </b-button>  
              </b-card>
            </div-->

            <div id="liqpay_checkout"></div>
            
            <!--template #modal-footer v-if="confirm_combipay_visible">
              <b-button 
                v-if="select_pay_method_visible && !widget_is_visible"
                variant="bruvis" 
                @click="confirm_combipay_visible = false"
              >
                &lt;&lt;&nbsp;Повернутись
              </b-button>
              <b-button variant="bruvis" @click="$bvModal.hide('orders-modal')">
                Закрити
              </b-button>
            </template-->

            <!--template #modal-footer v-else-->
            <template #modal-footer>  
              <b-button 
                variant="success" 
                @click="payOrders()" 
                :disabled="selectedTopay.length == 0"
                v-if="Object.keys(zakazy).length > 0 && salepoint.legalRN !=null && !salepoint.hidePrice"
                v-show="!widget_is_visible && !select_pay_method_visible"
              >
                <b-icon icon="credit-card"></b-icon> Оплатити {{amount}}&nbsp;&#8372;
              </b-button>
              <b-button 
                v-if="select_pay_method_visible && !widget_is_visible"
                variant="bruvis" 
                @click="select_pay_method_visible=false"
              >
                &lt;&lt;&nbsp;Повернутись
              </b-button>
              <b-button variant="bruvis" @click="$bvModal.hide('orders-modal')">
                Закрити
              </b-button>
          </template>
          </b-modal>

<!--ORDERS END--------------------------------------------------------------------->

<!-- Edit ORDER Begin ------------------------------------------------------------>
            <b-modal id="order-edit-modal" hide-header-close- 
              centered scrollable size="lg" :no-close-on-backdrop="true"
              ok-variant="bruvis"
              ok-title="Закрити"
              hide-footer
            >
              <template #modal-title>
                <b-icon icon="pencil" class="rounded bg-success p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Редагування замовлення</span>
              </template>
              <EditOrder
                :order2edit="order2edit"
                :dishes="dishes"
                :salepoint="salepoint" 
                @onsuccess = "init_zakazy(),selectedTopay = [],recalcSumToPay(),order2edit=null"
                @needlogin = "needlogin = true"
                @close = "$bvModal.hide('order-edit-modal')"
              ></EditOrder>

            </b-modal>
<!-- Edit ORDER END ------------------------------------------------------------> 


<!-- Zakazy Payed Begin ------------------------------------------------------------>
            <b-modal id="zakazy-payed-modal" hide-header-close- 
            v-if="!settings || !settings.hide_orders"
              centered scrollable size="lg" :no-close-on-backdrop="true" ok-only
              ok-variant="bruvis"
              ok-title="Закрити"
            >
              <template #modal-title>
                <b-icon icon="cart4" class="rounded bg-success p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплачені замовлення </span>
              </template>
              <ZakazyPayed 
                :zakazy_payed="zakazy_payed" 
                :mealtimes="mealtimes"
                @ondelete = "init_zakazy_afterpay()"
                @needlogin = "needlogin = true"
              ></ZakazyPayed>


              <!-- template v-if="zakazy_payed != null && Object.keys(zakazy_payed).length > 0">
                <b-card no-body>
                  <b-tabs small pills card vertical>
                    <b-tab v-for="(dt,index) of  Object.keys(zakazy_payed)" :title="new Date(dt) | dateFormat('dddd D MMMM', dateFormatConfig)" :key="index" class="text-center">
                      <template v-if="zakazy_payed[dt] != null">
                        <div class="perforated_check mx-auto" v-for="(ch,index2) of zakazy_payed[dt]" :key="index2">
                          <svg width="200px" viewBox="0 0 300 5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0,0 300,0" fill="none" stroke="#e2e3de" stroke-dasharray="3 3" stroke-width="20"/>
                          </svg>
                          <table class="teble mx-auto w-100">
                            <tr :key="index2">
                              <th colspan=2 class="text-center pb-3 pt-3">{{(mealtimes[ch.mealtime]).mnemo}} : рахунок № {{ch.rn}}</th>
                            </tr>
                            <tr v-for="(sp,index3) in ch.specs" :key="index3">
                              <td class="text-left pr-3 pl-1">{{sp.menu}}<template v-if="sp.quant>1"> (x{{sp.quant}})</template></td>
                              <td class="text-right text-nowrap pl-3 pr-1"><template v-if="sp.quant>1">{{sp.quant}} x </template>{{formatPrice(sp.price)}}</td>
                            </tr>
                            <tr class="bordered-top" :key="index2">
                              <td class="text-left pr-3 pl-1">Знижка</td>
                              <td class="text-right pl-3 pr-1">- {{formatPrice(ch.totalDiscountSum)}}</td>
                            </tr>
                            <tr :key="index2">
                              <th class="text-left pr-3 pl-1">Разом сплачено</th>
                              <th class="text-right pl-3 pr-1">{{formatPrice(ch.sumToPay)}}</th>
                            </tr> 
                          </table>  
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-card>  
              </template>
              <template v-else>
                <div class="text-center">
                  <h5>Замовлень немає</h5>
                </div>
              </template-->    
            </b-modal>
<!-- Zakazy Payed END ------------------------------------------------------------> 

<!--USER Profile--------------------------------------------------------------------->    
<b-modal 
  id="userprofile-modal" 
  v-if="!settings || !settings.hide_profile"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="file-person" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Профіль користувача</span>
  </template><!--    :enable_refill="salepoint && salepoint.enable_clubcard_refill"-->
  <UserProfile 
    :user="user" 
    :clubcards="clubcards"
    :settings = "settings"
    @onRefill = "init_afterrefill()"
    @needlogin = "needlogin = true"
  />
  
</b-modal>

<!--Archive--------------------------------------------------------------------->    
<b-modal 
  id="archive-modal" 
  v-if="!settings || !settings.hide_archive"
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="box-seam" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Архів замовлень</span>
  </template>
  
  <ArchivZakazov
    :mealtimes="mealtimes" 
    @needlogin = "needlogin = true"
  />
  
</b-modal>              


<!--PAY begi--------------------------------------------------------------------->    
<!--          <b-modal :show.sync="modalPayVisibile">
              <div slot="modal-header" class="modal-header">
                <h4 class="modal-title">{{ modalPayTitle }}Title</h4>
              </div>
              <div slot="modal-body" class="modal-body">
                Content
                  {{ modalPayContent }}
              </div>
          </b-modal>
-->          
<!--PAY end---------------------------------------------------------------------> 

<!--Remark begin--------------------------------------------------------------------->    
<b-modal 
  v-model = "num2remarkshow"
  id="remark-modal" 
  size="sm"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only-
  ok-variant-="bruvis"
  ok-title-="Закрити"
>
  <template #modal-title>
    <b-icon icon="box-seam" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Примітка до страви</span>
  </template>

  <b-form-group v-slot="{ ariaDescribedby }">
    <template v-if="dishes && dishes[num2remark] && (dishes[num2remark]).menumess">
        <b-form-checkbox-group
          v-model="dishComment"
          name="remarks"
        >  
          <b-form-checkbox
              v-for="(rm,index) of (dishes[num2remark]['menumess']).split(';')"
              :key="index"
              :value="rm"
              :aria-describedby="ariaDescribedby"
            >
              {{ rm }}
            </b-form-checkbox>
        </b-form-checkbox-group>  
    </template>  
    <b-form-input 
      v-model="dishCommentManual"
      v-if="dishes[num2remark]"
      :placeholder="(dishes && dishes[num2remark] && (dishes[num2remark]).menumess)?' --свій варіант':'коментар'"
    ></b-form-input>
  </b-form-group>  


  <template #modal-footer>
        <div class="w-100">
          <p class="float-left">&nbsp;</p>
          <b-button
            variant="bruvis"
            size="sm"
            class="float-right"
            @click="saveRemark()"
          >
            Ok
          </b-button>
        </div>
      </template> 
 
  
</b-modal>              

<!----------remark end------------------>

<!--User Block--------------------------------------------------------------------->    
<b-modal 
  id="user-block-modal" 
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="info-square" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">{{user_block_title}}</span>
  </template>
  
  <LegacyText :user_block="user_block_rn"/>
  
</b-modal>   



<!--debug begi--------------------------------------------------------------------->

          <b-modal id="debug-modal" hide-header-close- centered scrollable size="lg">
            <b-card no-body class="w-100">
              <h4>Salepoint</h4>
              <pre>
                {{salepoint}}
              </pre>  
              <h4>Ordfood</h4>
              <pre>
                {{ordfood}}
              </pre>
              <h4>Cart</h4>
              <pre>
                {{cart}}
              </pre>
              <h4>Checkbase</h4>
              <pre>              
                {{checkbase}}
              </pre>
              <h4>Zakazy</h4>
              <pre>              
                {{zakazy}}
              </pre>
              <h4>Zakazy payed</h4>
              <pre>              
                {{zakazy_payed_actual}}
              </pre>
              <h4>DebugInfo</h4>
              <pre>              
                {{debug}}
              </pre>         
              <h4>Dishes</h4>
              <pre>
                {{dishes}}
              </pre>
              <h4>Dishes_test</h4>
              <pre>
                {{dishes_test}}
              </pre>
              <h4>DSUM</h4>
              <pre>
               {{ dsum }}
              </pre>
              <h4>limits[onday]</h4>
              <pre v-if="limits && limits[onday]">
               {{ limits[onday] }}
              </pre>
              <h4>dishes_complex_parts_visibility</h4>
              <pre>
                {{dishes_complex_parts_visibility}}
              </pre>  

              
            </b-card> 
          </b-modal>
<!--debug end--------------------------------------------------------------------->

  </div>
</template>



<script>
import ZakazyPayed from './components/zakazy_payed.vue'
import UserProfile from './components/userprofile.vue'
import ArchivZakazov from './components/archive.vue'
import EditOrder from './components/orderedit.vue'
import SpinbuttonManual from './components/spinbuttonmanual.vue'
import FreeComplex  from './components/freecomplex.vue'
import LegacyText from './components/legacy.vue'
//import ConfirmCombi  from './components/confirm_combi.vue'
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'App',
  components: {
    ZakazyPayed,  
    UserProfile,
    ArchivZakazov,
    EditOrder,
    //  ConfirmCombi,
    GChart,
    SpinbuttonManual,
    LegacyText,
    FreeComplex
  },
  data: () => ({
    token: null,
    posts: [],
    errors: [],
    categories: [],
    categories_not_empty: [],
    catIndex: 0,
    //categoriesfilter: [],
    dishes: [],
      dishes_test: null,
      dishes_complex: {},
      dishes_complex_parts_visibility:[],
    complexlimit:[],      
    sort_mode: null,
    target_dish: null,
    target_dish_quant: 0,
    complexbusy: true,
    //targetComplex:[],
    //busy_button_state: {},
    specmarks: [],
    salepoints: null,
    salepoint: [],
    settings: {},
    salepoint_menu_rn: null,
    mealtimes: [],
    mealtime: null,
    cart: [],
    cartItems: [],
    cart_sum: 0,
    cart_count: 0,
    value2: null,

    nameLogin: "", //supervisor
    passwordLogin: "", //admin
    emptyFields: false,
    banners: null,
    user_block_title: null,
    user_block_rn: null,

    username: null,
    needlogin: false,
    needlogin2: false,

    error_login_msg: '',
    alertmessage: null,
    noopInterval: null,
    mark_tmp: null,
    district_scheme: false,

    ondates: null,
    ondate: null,
    holidays: null,
    limits: null,
    dsum: null,
    wsum: null,
    msum: null,
    overLimit: false,

   // loading: true
    busy: false,
    globalbusy: false,
    ordersbusy: false,

    ordfood : [],
    checkbase : [],

    orders: [],
    checks: [],
    zakazy: {},
    zakazy_payed: {},
    amount: 0,
    order2edit: null,
    
    clubcards: [],
    user: null,
    refill_modal_visible: false,

    //isMouseDown: false,
    //startX: null,
    scrollLeft: null,
    navElement: null,
    ondateTabIndex: null,
    ondateTabIndexBuffer: null,

    getStatusInt: null,
    lpay: null,
    globalrns: null,


    //modalPayVisibile: false,
    //modalPayTitle: null,
    //modalPayContent: null,
    widget_is_visible: false,
    select_pay_method_visible: false,
    combi_pay_method_visible: false,
    sum_clubcard: 0,
    sum_bankcard: 0,
    confirm_combipay_visible: false,

    selectedTopay: [],
    allSelected: false,

    chartData: [
      ["Task", "Hours per Day"],
      ["Work", 11],
      ["Eat", 2],
      ["Commute", 2],
      ["Watch TV", 2],
      ["Sleep", 7]
    ],
    chartOptions: {
      chart: {
        title: "БЖУ",
        is3D: true
      }
    },
    html_left_block: null,
    html_footer: null,
    logo: null,
    debug: '',
    debugmode: false,
    num2remark: null,
    num2remarkshow: false,
    dishComment:[],
    dishCommentManual: null
  }),
  computed: {
    minOndate: function(){
      //const now = new Date()
      //const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      //const minDate = new Date(today)
      const minDate = new Date()
      //return minDate
      return (this.ondates != null)?new Date(this.ondates[0]):minDate
    },
    maxOndate: function(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return (this.ondates != null)?new Date(this.ondates[this.ondates.length-1]):maxDate
    },
    zakazy_payed_actual: function(){
      var zk,ret = {}
      Object.keys(this.zakazy_payed).forEach((d) => {
        zk = []
        this.zakazy_payed[d].forEach((z) => {
          if(z['canceled'] !== true) zk.push(z)
        })
        if(zk.length > 0) ret[d] = zk
      })
      return ret
    }
    
  },
  watch:{
    zakazy(){
        this.overLimit=false
        
        Object.keys(this.zakazy).forEach((zkday) => {

          if(this.limits && this.limits[zkday] && (this.limits[zkday]).dayLimit>0 && this.dsum && this.dsum[zkday] > (this.limits[zkday]).balancePerDay){
            this.overLimit=true
            return
          }   
          this.zakazy[zkday].forEach((z) => {
            if(this.limits && this.limits[zkday] && (this.limits[zkday]).weekLimit>0 && this.wsum && this.wsum[z.wnum] > this.limits['w'+z.wnum]){
              this.overLimit=true
              return
            }   
            if(this.limits && this.limits[zkday] && (this.limits[zkday]).monthLimit>0 && this.msum && this.msum[z.mnum] > this.limits['m'+z.mnum]){
              this.overLimit=true
              return
            }    
          })
        })
        return
    },
    sort_mode(s){
      localStorage.setItem('sort_mode', s)
    }

  },
  created() {
    let liqpay = document.createElement('script');    
    liqpay.setAttribute('src',"https://static.liqpay.ua/libjs/checkout.js");
    document.head.appendChild(liqpay);
  },
  mounted() {
    this.init()
    if(this.$refs["ondates-tabs"])
      this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
/*
    this.navElement.addEventListener("mousedown", this.onMouseDown);
    this.navElement.addEventListener("mouseup", this.onMouseUp);
    this.navElement.addEventListener("mousemove", this.onMouseMove);
    this.navElement.addEventListener("mouseleave", this.onMouseLeave);

    this.navElement.addEventListener("touchstart", this.onTouchStart);
    this.navElement.addEventListener("touchmove", this.onTouchMove);
*/    

  },
/*  onBeforeDestroy() {
    this.navElement.removeEventListener("mousedown", this.onMouseDown);
    this.navElement.removeEventListener("mouseup", this.onMouseUp);
    this.navElement.removeEventListener("mousemove", this.onMouseMove);
    this.navElement.removeEventListener("mouseleave", this.onMouseLeave);

    this.navElement.removeEventListener("touchstart", this.onMouseDown);
    this.navElement.removeEventListener("touchmove", this.onMouseMove);
  },
  */
  methods: {
    init(rn,ondate,mealtime){
      this.globalbusy = true
      
      /*
        this.$toasted.error('Loaded successfull outline',{
          theme:'outline',
          action : {
            text : 'X',
            onClick : (e, toastObject) => {
                toastObject.goAway(0)
            }
          }  
        }).goAway(20000)
*/
       
      /*
      this.$toasted.info('Loaded successfull outline',{theme:'outline'}).goAway(100000)
      this.$toasted.info('bubble',{theme:'bubble'}).goAway(100000)
      this.$toasted.info('bubble bruvis',{theme:'bubble',className:'bruvis'}).goAway(100000)
      this.$toasted.info('bubble victor',{theme:'bubble',className:'victor'}).goAway(100000)
      this.$toasted.error('bubble victor',{theme:'outline',className:'victor'}).goAway(100000)
      /*
      this.$toasted.register('my_app_error', 'Oops.. Something Went Wrong..', {
          type : 'error',
          theme : 'outline', 
          icon : 'chevron-left'
      })
      this.$toasted.register('my_app_error2', 'Oops.. Something Went Wrong..', {
          type : 'info',
          theme : 'bubble'
      })

      this.$toasted.global.my_app_error()
      this.$toasted.global.my_app_error2()
      */
      
      if(rn == null) rn=(this.salepoint && this.salepoint.rn != null)?this.salepoint.rn:null;
      if(ondate == null) ondate=(this.ondate != null)?this.ondate:null;
      if(mealtime == null) mealtime=(this.mealtime != null)?this.mealtime:null;
      
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init","rn":rn,"ondate":ondate,"mealtime":mealtime}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts      = response.data
            this.dishes     = this.posts.dishes
            this.dishes_complex     = this.posts.dishes_complex
              //Object.values(this.dishes_complex).forEach((dc)=>{
              //  this.dishes_complex_parts_visibility[dc['rp']]=true
              //  console.log(dc['rp'],this.dishes_complex_parts_visibility)
              //})
            this.specmarks  = this.posts.specmarks
            this.salepoints = this.posts.salepoints
            this.salepoint  = this.posts.salepoint
            if(this.salepoint) this.salepoint_menu_rn = this.salepoint.rn
            this.mealtimes = this.posts.mealtimes
            this.mealtime  = this.posts.mealtime
            this.categories = this.posts.categories  
            this.ondates = this.posts.ondates
            this.limits  = this.posts.limits
            this.ondate  = this.posts.ondate
            this.ondateTabIndex = this.posts.ondate_index
              if(this.ondateTabIndex===0 && this.$refs.scrollLeft && this.navElement){
                this.navElement.scrollLeft =0
                this.$refs.scrollLeft.style.left = "0px"
                this.$refs.scrollRight.style.right = "0px"
              }  
            this.ondateTabIndexBuffer = this.posts.ondate_index
            this.holidays= this.posts.holibase
            this.cart       = this.posts.order.basket
            this.cartItems  = this.posts.order.item
            this.cart_count = parseInt(this.posts.order.summary.sum_count)
            this.cart_sum   = parseFloat(this.posts.order.summary.sum_price)
            this.username   = this.posts.auth.username
            this.token      = this.posts.auth.token
            this.needlogin  = false
            this.sort_mode  = localStorage.getItem('sort_mode') || this.posts.sort
              if(this.sort_mode !== null) {
                this.dishes_sort(this.sort_mode) 
              }else{  
                this.dishes_test  = this.posts.dishes
              }  
            this.ordfood    = this.posts.ordfood  
            this.checkbase  = this.posts.checkbase

            this.orders    = this.posts.orders  

            // complex_limit - подтягиваем флаги состояний
            this.getComplexLimits()
            /*
            this.complexlimit = []
            if(this.salepoint.onlyOnePos){
              //проверяем корзину
              if(this.cart && this.cartItems){
                this.cart.forEach((i)=>{
                  if((this.cartItems[i.menu_rn]).complex)
                    if(!this.complexlimit) 
                      this.complexlimit=[]
                    if(!this.complexlimit[this.ondate]) 
                      this.complexlimit[this.ondate]=[]
                    if(!this.complexlimit[this.ondate][this.mealtime]){ 
                      this.complexlimit[this.ondate][this.mealtime] = i.menu_rn  
                      console.log('Set complex limit from basket!',this.complexlimit)
                    }  
                })
              }
              //проверяем заказы
              if(this.orders){
                var ondatmp
                Object.values(this.orders).forEach((o)=>{
                  //console.log('Looking for a complex in orders',o)
                  o.specs.forEach((s)=>{
                    if(this.dishes && this.dishes[s.menu_rn] && (this.dishes[s.menu_rn]).complex){
                      ondatmp = (new Date(o.ordondate)).getFullYear()+'-'+('0'+((new Date(o.ordondate)).getMonth()+1)).slice(-2)+'-'+('0'+(new Date(o.ordondate)).getDate()).slice(-2)  
                      if(!this.complexlimit) 
                        this.complexlimit=[]
                      if(!this.complexlimit[ondatmp]) 
                        this.complexlimit[ondatmp] = []
                      if(!this.complexlimit[ondatmp][o.mealtime]){
                        this.complexlimit[ondatmp][o.mealtime] = s.menu_rn  
                        console.log('Set complex limit flags from orders!',this.complexlimit)
                      }

                      //console.log('Found complex in orders '+ s.menu_rn+'-'+ondatmp)
                    }  
                  })
                })
              }

            }*/
            //-----------------------------------------------------------------------------

            this.checks    = this.posts.checks
            this.zakazy    = this.posts.zakazy
            this.dsum      = this.posts.dsum
            this.wsum      = this.posts.wsum
            this.msum      = this.posts.msum
            this.zakazy_payed    = this.posts.zakazy_payed
            this.alertmessage    =  this.posts.message
            this.settings   = this.posts.settings


            this.clubcards =  this.posts.clubbase
            this.user      =  this.posts.user

            this.debugmode = this.posts.debugmode

            if(this.posts.html_leftblock) this.html_left_block = this.posts.html_leftblock
            if(this.posts.html_footer) this.html_footer = this.posts.html_footer
            if(this.posts.debug) this.debug = this.posts.debug
            if(this.posts.logo) this.logo   = this.posts.logo

            this.globalbusy = false

            //this.$toasted.success('Loaded successfull').goAway(2000)
          }else{
            this.globalbusy = false
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              if(response.data.banners) this.banners = response.data.banners
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });

        if(this.noopInterval === null)
          this.noopInterval = setInterval(function(){
                            this.$axios.post('/', {"action":"noop"} )
                                  .then((response) => {
                                    if(response.data.success == false || response.data.success == 'false'){
                                      if(response.data.error == "auth" || response.data.error == "tokenout"){
                                        this.needlogin = true
                                        clearInterval(this.noopInterval)
                                      }else if(response.data.message != ''){
                                        this.alertmessage = response.data.message
                                        if(response.data.wait != true){
                                          clearInterval(this.noopInterval)
                                        }
                                        //this.$toasted.error(response.data.message).goAway(5000)
                                      }
                                    }else{
                                      this.alertmessage = ''
                                    }
                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                    clearInterval(this.noopInterval)
                                  });
                            }.bind(this), 60000);

    },
    init_zakazy(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init_zakazy"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts          = response.data
            this.zakazy         = this.posts.zakazy
            this.dsum      = this.posts.dsum
            this.wsum      = this.posts.wsum
            this.msum      = this.posts.msum
            this.orders         = this.posts.orders
            this.checks         = this.posts.checks

            this.getComplexLimits() 
            
            //this.globalbusy = false
          }else{
            //this.globalbusy = false
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_zakazy_afterpay(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"init_zakazy_afterpay"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts          = response.data
            this.zakazy         = this.posts.zakazy
            this.dsum      = this.posts.dsum
            this.wsum      = this.posts.wsum
            this.msum      = this.posts.msum
            this.zakazy_payed   = this.posts.zakazy_payed
            this.clubcards      = this.posts.clubbase
            this.limits         = this.posts.limits
            //this.globalbusy = false
          }else{
            //this.globalbusy = false
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_afterrefill(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      var postData = {"action":"afterrefill"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            this.posts    = response.data
            this.clubcards= this.posts.clubbase
          }else{
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
    init_order(rn,ondate,mealtime){

        if(rn == null) rn=(this.salepoint.rn != null)?this.salepoint.rn:null;
        if(ondate == null) ondate=(this.ondate != null)?this.ondate:null;
        if(mealtime == null) mealtime=(this.mealtime != null)?this.mealtime:null;

        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer_'+this.token
          },
          'withCredentials': true
        }
        var postData = {"action":"init_order","rn":rn,"ondate":ondate,"mealtime":mealtime}
        this.$axios.post('/', postData,  axiosConfig)
                        
                .then((response) => {
                    if(response.data.success != 'false'){
                      this.posts      = response.data

                      this.cart       = this.posts.order.basket
                      this.cartItems  = this.posts.order.item
                      this.cart_count = parseInt(this.posts.order.summary.sum_count)
                      this.cart_sum   = parseFloat(this.posts.order.summary.sum_price)
                      this.ordfood    = this.posts.ordfood  
                      this.checkbase  = this.posts.checkbase
                      this.orders    = this.posts.orders  
                      this.checks    = this.posts.checks
                      this.zakazy    = this.posts.zakazy
                      this.dsum      = this.posts.dsum
                      this.wsum      = this.posts.wsum
                      this.msum      = this.posts.msum
                      this.limits         = this.posts.limits
                      this.zakazy_payed    = this.posts.zakazy_payed

                      //this.$toasted.success('Updated successfull').goAway(2000)
                      this.serviceMessage('Updated successfull')

                      this.getComplexLimits()

                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }
                    }
                   
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
        //this.loading = false
    },
    getComplexLimits(){      // complex_limit - подтягиваем флаги состояний
      if(!this.salepoint.onlyOnePos) return
      this.complexlimit = []

      //проверяем корзину
      if(this.cart && this.cartItems){
        this.cart.forEach((i)=>{
          if((this.cartItems[i.menu_rn]).complex){
            if(!this.complexlimit) 
              this.complexlimit=[]
            if(!this.complexlimit[this.ondate]) 
              this.complexlimit[this.ondate]=[]
            if(!this.complexlimit[this.ondate][this.mealtime]){ 
              this.complexlimit[this.ondate][this.mealtime] = i.menu_rn  
              console.log('Set complex limit from basket!',this.complexlimit)
            }  
          }  
        })
      }
      //проверяем заказы
      if(this.orders){
        var ondatmp
        Object.values(this.orders).forEach((o)=>{
          //console.log('Looking for a complex in orders',o)
          o.specs.forEach((s)=>{
            if(this.dishes && this.dishes[s.menu_rn] && (this.dishes[s.menu_rn]).complex){
              ondatmp = (new Date(o.ordondate)).getFullYear()+'-'+('0'+((new Date(o.ordondate)).getMonth()+1)).slice(-2)+'-'+('0'+(new Date(o.ordondate)).getDate()).slice(-2)  
              if(!this.complexlimit) 
                this.complexlimit=[]
              if(!this.complexlimit[ondatmp]) 
                this.complexlimit[ondatmp] = []
              if(!this.complexlimit[ondatmp][o.mealtime]){
                this.complexlimit[ondatmp][o.mealtime] = s.menu_rn  
                console.log('Set complex limit flags from orders!',this.complexlimit)
              }
            }  
          })
        })
      }
    },
    dishes_sort(mode){
      switch (mode) {
          case 'mnemo_asc':
            this.dishes_test = this._.orderBy(this.dishes, 'mnemo', 'asc')
            /*
            this.dishes_test = this._.orderBy(this.dishes, 'mnemo', 'asc').map(item =>  {
                              //const ob = {}
                //ob[item.rn] = item
                return item[item.rn] = item 
            })
            */
            break  
          case 'price_asc':
            this.dishes_test = this._.orderBy(this.dishes, 'price', 'asc')
            break
          case 'price_desc':  
            this.dishes_test = this._.orderBy(this.dishes, 'price', 'desc')
            break
          default:
            return true
      }
      if(this.sort_mode !== mode){
        this.$axios.post('/', {"action":"setsort","rn": mode} )
          .then((response) => {
            if(response.data.success == false || response.data.success == 'false'){
              if(response.data.error == "auth" || response.data.error == "tokenout"){
                this.needlogin = true
              }
            }
          })
        this.sort_mode = mode  
      }    

/**
 * 
 * 
 * _(data)
    .map(function(value, key) {
        return _.defaults({ name: key }, value);
    })
    .sortBy('name')
    .value();
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */


    },
    doLogin(){
       
      if (this.nameLogin === "" || this.passwordLogin === "") {
        this.emptyFields = true;
      } else {
        this.busy = true
        var postData = {
          "action":"login",
          "username":this.nameLogin,
          "password":this.passwordLogin
        }
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            },
          'withCredentials': true
        }
        this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
                .then((response) => {
                  this.error_login_msg=response.data.message
                  if(response.data.success === true){
                    this.username = response.data.username
                    this.token = response.data.tokenphp
                    this.init()
                  }else{
                    this.busy = false
                  }
                })
                .catch(error => {
                    console.error('There was an error!', error)
                }); 
      }      
    },
    logout(){
      var postData = {
        "action":"logout",
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            this.username = null
            this.init()
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
    getorders(rn){
      if(rn == null) rn=(this.salepoint.rn != null)?this.salepoint.rn:null;
      this.globalbusy=true
      var postData = {
        "action":"getorders"
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != 'false'){
              this.orders = response.data.orders
              this.checks = response.data.checks
              this.amount = 0
              this.selectedTopay = []

              this.$bvModal.show('orders-modal')

              //console.log('orders')
              //console.log(this.orders)
              //console.log('checks')
              //console.log(this.checks)
            }else{
              if(response.data.error=="auth" || response.data.error=="tokenout"){
                this.needlogin = true
              }
            }
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
    /*
    cleartoken(){
      var postData = {
        "action":"cleartoken",
      }
      var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
          },
          'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            this.username = null
            this.init()
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
*/
/*
    makeToast(){
     
        this.$toasted.show('Toast show', {
          icon: 'check',
          action : {
              text : 'Cancel',
              onClick : (e, toastObject) => {
                          toastObject.goAway(0);
                         }
          },


        })
    },
*/    
    addCart(rn){

      var parms = {"action":"addcart","rn":rn}, rn2remove

      //when complex limit is on
      if(this.salepoint.onlyOnePos && (this.dishes[rn]).complex){
        if(!this.complexlimit || !this.complexlimit[this.ondate] || !this.complexlimit[this.ondate][this.mealtime]){
          if(!this.complexlimit[this.ondate])
            this.complexlimit[this.ondate] = []
          if(!this.complexlimit[this.ondate][this.mealtime]){  
            this.complexlimit[this.ondate][this.mealtime] = rn  
            console.log('Complex limit!',this.complexlimit)
          }  
        }else{
          //console.log(this.cart.map((itm)=>itm.menu_rn))
          //console.log(this.complexlimit[this.ondate][this.mealtime])
          //console.log((this.cart.map((itm)=>itm.menu_rn)).indexOf(this.complexlimit[this.ondate][this.mealtime]))
          rn2remove = this.complexlimit[this.ondate][this.mealtime]
          var pos2remove = (this.cart.map((itm)=>itm.menu_rn)).indexOf(rn2remove)
          
          if(pos2remove>-1 && rn != rn2remove){
            parms = {"action":"replacecart","rn1":rn2remove,"rn2":rn}
            this.complexlimit[this.ondate][this.mealtime] = rn
          }else{
            this.$toasted.error('Можна обрати лише одну комплексну страву!').goAway(2000)
            return
          }  
        }
      }

      (this.dishes[rn]).busy_button_state = 1;

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer_'+this.token
        },
        'withCredentials': true
      }
      this.$axios.post('/', parms, axiosConfig )
                  .then((response) => {
                    (this.dishes[rn]).busy_button_state = 0;
                    if(response.data.success != false && response.data.success != "false"){
                        if(rn2remove){
                          (this.dishes[rn2remove]).quant_cart = 0
                          this.$toasted.error('Можна обрати лише одну комплексну страву!').goAway(3000)
                          this.$toasted.success("'"+(this.dishes[rn2remove]).mnemo + "' замінено на '" + (this.dishes[rn]).mnemo+"'").goAway(4000)
                        }  
                        this.cart = response.data.basket
                        this.cartItems = response.data.item
                        this.cart_count = parseFloat(response.data.summary.sum_count)
                        this.cart_sum = parseFloat(response.data.summary.sum_price)
                        this.cart.forEach((vale) => {
                          (this.dishes[vale.menu_rn]).quant_cart = vale.quant;
                        })
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(3000)
                      } 
                    }
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },
    clearCart(){
      this.cart.forEach((vale) => {
                      (this.dishes[vale.menu_rn]).quant_cart = 0
                    })
      this.cart = []
      this.cartItems = []
      this.cart_count = 0
      this.cart_sum = 0
    },
    setCartQuant(rn, val){

      

      //when complex limit is on
      if(this.salepoint.onlyOnePos && (this.dishes[rn]).complex){
        if(val==1){
          if(!this.complexlimit || !this.complexlimit[this.ondate] || !this.complexlimit[this.ondate][this.mealtime]){
            if(!this.complexlimit[this.ondate])
              this.complexlimit[this.ondate] = []
            if(!this.complexlimit[this.ondate][this.mealtime]){
              this.complexlimit[this.ondate][this.mealtime] = rn  
              console.log('Complex limit!',this.complexlimit)
            }  
          }else{
            this.$toasted.error('Можна обрати лише одну комплексну страву!').goAway(2000)
            return
          }
        }     
        if(val==0 && this.complexlimit && this.complexlimit[this.ondate] && this.complexlimit[this.ondate][this.mealtime]===rn)
          this.complexlimit[this.ondate][this.mealtime] = null
      }  

      this.$axios.post('/', {"action":"setcartquant","rn":rn,"quant":val}, { withCredentials: true } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      (this.dishes[rn]).quant_cart = val
                      this.cart = response.data.basket
                      this.cartItems = response.data.item
                      //this.cart_count = parseInt(response.data.summary.sum_count)
                      this.cart_count = parseFloat(response.data.summary.sum_count)
                      this.cart_sum = parseFloat(response.data.summary.sum_price)
                      this.cart.forEach((vale) => {
                        (this.dishes[vale.menu_rn]).quant_cart = vale.quant
                      })
                     }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(3000)
                      }  
                    }  
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },
    setCartComment(rn, val){
      this.$axios.post('/', {"action":"setcartcomment","rn":rn,"comment":val}, { withCredentials: true } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      //(this.dishes[rn]).quant_cart = val
                      this.cart = response.data.basket
                      //this.cartItems = response.data.item
                      //this.cart_count = parseFloat(response.data.summary.sum_count)
                      //this.cart_sum = parseFloat(response.data.summary.sum_price)
                      //this.cart.forEach((vale) => {
                      //  (this.dishes[vale.menu_rn]).quant_cart = vale.quant
                      //})
                     }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(3000)
                      }  
                    }  
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },
    reservOrder(mode,x,y,z) {
      if(mode=='cancel'){ 
        console.log('Cancel save order')
        return
      }
      this.busy = true
      this.$axios.post('/', {"action":"saveorder","mode":mode} )
                  .then((response) => {
                    this.busy = false
                    if(response.data.success != false && response.data.success != 'false'){
                      if(response.data.message === 'saveordermode'){
                        
                        //есть ли в новом заказе комплексное блюдо
                        var dish2test,complexflag=false

                        if(this.salepoint.onlyOnePos)
                          this.cart.forEach(ci => {
                            dish2test = this.dishes[ci.menu_rn]
                            if(dish2test.complex && dish2test.complexType==1) complexflag=true
                          });

                        if(complexflag){
                          console.log(this.showMsgBoxOrderSaveModeComplexLimit())
                        }else{ //в новом заказе комлекса нет или вообще нет ограниченний на комплексы
                          console.log(this.showMsgBoxOrderSaveMode())
                        }
                        return
                      }
                      console.log('Save order')
                      console.log(response.data)
                      if(response.data.success === true){
                        //this.$toasted.success(response.data.message).goAway(3000)

                        if(x || y || z) 
                          this.init(x,y,z)
                        else{
                          this.serviceMessage('Updated successfull')
                          if(response.data.warning){
                            //this.$toasted.error(response.data.warning,{theme:'bubble',className:'victor'}).goAway(8000)
                            //this.$toasted.error(response.data.warning,{theme:'bubble'}).goAway(8000)
                            this.$toasted.error(response.data.warning,{
                              theme:'outline',
                              action : {
                                text : 'X',
                                onClick : (e, toastObject) => {
                                    toastObject.goAway(0)
                                }
                              }  
                            }).goAway(20000)
                          }  
                          this.$bvModal.hide('basket-modal2')
                          this.clearCart()
                          this.init_order()                      
                        }
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000)
                      }  
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }    
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
      
    },
    showMsgBoxOrderSaveMode() {
        this.orderSaveMode = ''
        this.$bvModal.msgBoxConfirm('На цей час вже маєте неоплачене замовлення', {
          title: 'Зробіть вибір',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Замінити',
          cancelVariant: 'success',
          cancelTitle: 'Додати',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.reservOrder('replace')
            }else if(value === false) {
              return this.reservOrder('append')              
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Save order '+err)
          })
    },
    showMsgBoxOrderSaveModeComplexLimit() {
        this.orderSaveMode = ''
        this.$bvModal.msgBoxConfirm('На цей час вже маєте неоплачене замовлення. Замінити його новим?', {
          title: 'Зробіть вибір',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Замінити',
          cancelVariant: 'danger',
          cancelTitle: 'Відмінити',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.reservOrder('replace')
            }else if(value === false) {
              return this.reservOrder('cancel')              
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Save order '+err)
          })
    },
    showMsgBoxRequestSaveOrder(x,y,z) {
        this.$bvModal.msgBoxConfirm('Ви не зберегли попереднє замовлення. Зберегти?', {
          title: 'Кошик не порожній!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'bruvis',
          okTitle: 'Так',
          cancelVariant: 'bruvis',
          cancelTitle: 'Ні',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => {
            if(value === true) {
              console.log('Replace order')
              this.reservOrder('replace',x,y,z)
            }else{ // if(value === false) 
              //this.init(x,y,z)
              console.log('Clear basket - GO INIT')
              this.init(x,y,z)
            }
          })
          .catch(err => {
            // An error occurred
            console.log('RequestSaveOrder '+err)
            this.init(x,y,z)
          })
    },
    editOrder(zkz){
      this.order2edit = zkz
      this.$bvModal.show('order-edit-modal')
    },
    removeOrder(rn,confirm) {
      if(!confirm){
        this.showMsgBoxOrderRemoveConfirm(rn)
        return
      }
      if(confirm === 'false'){
        return
      }
      this.ordersbusy = true
      this.$axios.post('/', {"action":"removeorder","rn":rn } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      this.$toasted.success(response.data.message).goAway(3000)
                      this.init_zakazy()
                      this.selectedTopay = this.selectedTopay.filter(item => item != rn)
                      this.recalcSumToPay()
                      /*
                      this.$nextTick(() => { //должно успеть перечитать
                        this.reloadSelectedToPay()
                      })
                      */
                      this.ordersbusy = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });
      
    },
    showMsgBoxOrderRemoveConfirm(rn) {
        this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте видалити замовлення?', {
          title: 'Підтвердження',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Видалити',
          cancelVariant: 'success',
          cancelTitle: 'Відміна',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.removeOrder(rn,'true')
            }else if(value === false) {
              return 
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Save order '+err)
          })
    },
    PayClubCard(rns,confirm) {
      if(!confirm){
        this.showMsgBoxPayClubCardConfirm(rns)
        return
      }
      if(confirm === 'false'){
        return
      }

      this.ordersbusy = true
      this.$axios.post('/', {"action":"payordersbyclubcard","rn":rns } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      this.$toasted.success(response.data.message).goAway(3000)
                      this.zakazy = {}
                      this.selectedTopay=[]
                      this.recalcSumToPay()
                      this.init_zakazy_afterpay()
                      this.ordersbusy = false
                      this.select_pay_method_visible = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000)
                        
                        // в случае, когда оплачены не все заказы, то надо перечитать

                        this.zakazy = {}
                        this.selectedTopay=[]
                        this.recalcSumToPay()
                        this.init_zakazy_afterpay()
                        this.select_pay_method_visible = false
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });





    },  
    showMsgBoxPayClubCardConfirm(rns) {

        if((this.clubcards[0]).balance + (this.clubcards[0]).cardlimit < this.amount){
          this.$bvModal.msgBoxOk('На вашому рахунку не вистачає коштів для оплати', {
              title: 'Попередження',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'bruvis',
              okTitle: 'Ok',
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true,
              headerClass: 'bg-danger text-white border-danger',
            })
            .then(value => {
              console.log(value)
              return
            })
          return
        }


        this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте оплатити замовлення клубною карткою?', {
          title: 'Підтвердження',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Оплатити',
          cancelVariant: 'bruvis',
          cancelTitle: 'Відміна',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.PayClubCard(rns,'true')
            }else if(value === false) {
              return 
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Pay order '+err)
          })
    },
    /**********************************COMBI PAY BEGIN**************************************************** */
    testCombiPay(rns) {
      this.ordersbusy = true
      this.combi_pay_method_visible = false
      this.sum_clubcard = 0
      this.sum_bankcard = 0
      this.$axios.post('/', {"action":"precombipay","rn":rns } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      if(response.data.message) this.$toasted.success(response.data.message).goAway(3000)
                      this.sum_clubcard = response.data.sum_clubcard
                      this.sum_bankcard = response.data.sum_bankcard
                      console.log('ClubCard-'+this.sum_clubcard+' , Bankcard-'+this.sum_bankcard)
                      //if(this.sum_clubcard > 0 && this.sum_bankcard > 0){
                      if(this.sum_bankcard > 0){
                        this.combi_pay_method_visible = true
                      }
                      this.ordersbusy = false
                      //this.select_pay_method_visible = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else if(response.data.error=="scheme"){
                        this.widget_is_visible = false
                        this.select_pay_method_visible=false
                        this.selectAllOff()
                        this.selectedTopay = []
                        this.$toasted.error(response.data.message).goAway(3000)
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000)
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });





    },



    preCombiPay(rns,confirm) {
      if(!confirm){
        this.showMsgBoxCombiPayConfirm(rns)
        return
      }
      if(confirm === 'false'){
        return
      }

      this.ordersbusy = true
      this.$axios.post('/', {"action":"precombipay","rn":rns } )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != 'false'){
                      this.$toasted.success(response.data.message).goAway(3000)

                      this.zakazy = {}
                      this.selectedTopay=[]
                      this.recalcSumToPay()
                      this.init_zakazy_afterpay()
                      
                      this.ordersbusy = false
                      this.select_pay_method_visible = false
                    }else{
                      this.ordersbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000)
                        
                        // в случае, когда оплачены не все заказы, то надо перечитать

                        this.zakazy = {}
                        this.selectedTopay=[]
                        this.recalcSumToPay()
                        this.init_zakazy_afterpay()
                        this.select_pay_method_visible = false
                      }
                    }  
                  })
                  .catch(error => {
                    this.ordersbusy = false
                    console.error('There was an error!', error)
                  });





    },
    showMsgBoxCombiPayConfirm(rns) {
        /*
        if((this.clubcards[0]).balance + (this.clubcards[0]).cardlimit < this.amount){
          this.$bvModal.msgBoxOk('На вашому рахунку не вистачає коштів для оплати', {
              title: 'Попередження',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'bruvis',
              okTitle: 'Ok',
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true,
              headerClass: 'bg-danger text-white border-danger',
            })
            .then(value => {
              console.log(value)
              return
            })
          return
        }
      */

        this.$bvModal.msgBoxConfirm('Ви дійсно бажаєте оплатити замовлення клубною карткою \u20B4' + this.sum_clubcard + ' та доплатити \u20B4'+this.sum_bankcard+ ' ?', {
          title: 'Підтвердження',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Оплатити',
          cancelVariant: 'bruvis',
          cancelTitle: 'Відміна',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value === true) {
              return this.PayCreditCard(rns,'combi')
            }else if(value === false) {
              return 
            }
          })
          .catch(err => {
            // An error occurred
            console.log('Combi Pay order '+err)
          })
    },



    /**************************COMBI PAY END***************************************************************/
    recalcSumToPay(){
      var sum = 0;
      this.selectedTopay.forEach((itm) => {
        switch (itm.charAt(0)) {
          case 'c':
            sum += (this.checks[itm.substring(1)]).sumToPay
            break
          case 'o':  
            sum += (this.orders[itm.substring(1)]).sumToPay
            break
        }
      })
      this.amount = Math.round(sum * 100) / 100
    },
    selectAll(){
      let zkday,z
      this.selectedTopay = []
      this.allSelected = !this.allSelected
      if (this.allSelected) {
        for (zkday in this.zakazy) {
          for (z in this.zakazy[zkday]){
            //console.log(this.zakazy[zkday][z])
            if(new Date() < new Date(this.zakazy[zkday][z].deadln)){
              if(document.querySelector('#checkbox'+this.zakazy[zkday][z].rn) && document.querySelector('#checkbox'+this.zakazy[zkday][z].rn).disabled === false)
                this.selectedTopay.push(this.zakazy[zkday][z].rn)
            }  
          }  
        }
      }
      this.recalcSumToPay()
    },
    selectAllOff(){
      this.allSelected = false
      this.recalcSumToPay()
    },
    /*
    reloadSelectedToPay(){
      let zkday,zpayOrders()
      this.selectedTopay = []
      for (zkday in this.zakazy) {
        for (z in this.zakazy[zkday]){
          this.selectedTopay.push(this.zakazy[zkday][z].rn)
        }  payOrders()
      }
      this.recalcSumToPay()
    },*/
    payOrders(){
      console.log(this.selectedTopay)
      if(this.selectedTopay.length == 0) return false

      //this.salepoint.clubCardPayment = true
      //this.salepoint.creditCardPayment = false

      if(this.salepoint.clubCardPayment && this.salepoint.creditCardPayment){
        if(this.clubcards.length === 0){
          return this.PayCreditCard(this.selectedTopay.join())  
        }else{
          if(this.salepoint.combiPay){
            this.testCombiPay(this.selectedTopay.join())
          } 
          this.select_pay_method_visible = true 
          return false
        }  
      }else if(this.salepoint.clubCardPayment){
        if(this.clubcards.length === 0){
          this.$toasted.error('Помилка: жодної клубної карти не закріплено за користувачем').goAway(5000)
        }else{
          return this.PayClubCard(this.selectedTopay.join())    
        }
      }else if(this.salepoint.creditCardPayment){
        return this.PayCreditCard(this.selectedTopay.join())
      }else{
        this.$toasted.error('Помилка конфігурації: не задано метод платежу').goAway(3000)
      }      
      /*
      this.globalbusy = true

      this.$axios.post('/', {"action":"payorders","rn":this.selectedTopay.join()} )
                  .then((response) => {

                    if(response.data.success != 'false'){                    
                      this.globalbusy = false
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#liqpay_checkout",
                        "language": "uk",
                        "mode": "embed" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)

                            let getStatusInt = setInterval(function(){
                              this.$axios.post('/', {"action":"getpaystatus","rn":this.selectedTopay.join()} )
                                  .then((response) => {
                                      let result = response.data
                                      switch(result.status){
                                          case 'wait':
                                            console.log('wait')
                                            break
                                          case 'success':
                                            console.log('success')
                                            clearInterval(getStatusInt)
                                            this.$toasted.success(result.message).goAway(3000)              
                                            this.onSuccessPay()
                                            break
                                          case 'ready':
                                            console.log(result.data)
                                            clearInterval(getStatusInt)
                                            this.$toasted.warning(result.message).goAway(3000)
                                            break
                                          default:
                                            console.log(result)  
                                        } 

                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                  });
                            }.bind(this), 2000);
                        }.bind(this)
                      ).on("liqpay.ready", function(data){

                        console.log(data.status)
                        this.widget_is_visible = true

                        }.bind(this)
                      ).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
                  */
    },
    /*
    getPayStatus(){
      this.$axios.post('/', {"action":"getpaystatus","rn":this.selectedTopay.join()} )
                  .then((response) => {
                    return response.data
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });

    },
    */
    requestToPayStatus(rns){
      console.log('lpay')
      console.log(this.lpay)
      //eslint-disable-next-line
      //this.lpay = LiqPayCheckout.close()
      //console.log(this.lpay)
      this.$axios.post('/', {"action":"getpaystatus","rn":rns} )
                                  .then((response) => {
                                      let result = response.data
                                      switch(result.status){
                                          case 'wait':
                                            console.log('wait')
                                            this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                                            setTimeout(() => this.requestToPayStatus(rns), 2000)
                                            break
                                          case 'success':
                                            console.log('success')
                                            // проверить!!!!!
                                            //this.lpay.destroy()
                                            // eslint-disable-next-line
                                            //LiqPayCheckout.destroy()
                                            this.$toasted.success(result.message).goAway(3000)              
                                            this.onSuccessPay()
                                            break
                                          case 'ready':
                                            console.log(result.data)
                                            this.$toasted.error(result.message).goAway(3000)
                                            this.onSuccessPay()
                                            break
                                          default:
                                            console.log(result)  
                                        } 

                                  })
                                  .catch(error => {
                                    this.globalbusy = false
                                    console.error('There was an error!', error)
                                  });
    },


    PayCreditCard(rns,mode){
      this.globalbusy = true
      this.$axios.post('/', {"action":"payorders","rn":rns,"mode": mode} )
                  .then((response) => {

                    if(response.data.success != 'false'){  
                      //-----------------------------------                  
                      this.globalbusy = false
                      //if(this.lpay) this.lpay = null
                      //

                      this.globalrns = rns

                      if(this.lpay){
                              //eslint-disable-next-line
                              this.lpay = LiqPayCheckout.init({
                                "data": response.data.data,
                                "signature": response.data.signature,
                                "embedTo": "#liqpay_checkout",
                                "language": "uk",
                                "mode": "embed" // embed || popup
                              })
                      }else{
                                //eslint-disable-next-line
                                this.lpay = LiqPayCheckout.init({
                                  "data": response.data.data,
                                  "signature": response.data.signature,
                                  "embedTo": "#liqpay_checkout",
                                  "language": "uk",
                                  "mode": "embed" // embed || popup
                                }).on("liqpay.callback", function(data){  
                                    console.log('Callback '+this.globalrns)
                                    console.log(data.status)
                                    setTimeout(() => this.requestToPayStatus(this.globalrns), 2000)

                                    //this.globalbusy = true
                                      //if(this.getStatusInt) clearInterval(this.getStatusInt)
                                      /*
                                      this.getStatusInt = setInterval(() => {
                                        console.log('Request for paystatus of '+rns)
                                        this.$axios.post('/', {"action":"getpaystatus","rn":rns} )
                                            .then((response) => {
                                                let result = response.data
                                                switch(result.status){
                                                    case 'wait':
                                                      console.log('wait')
                                                      this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                                                      break
                                                    case 'success':
                                                      console.log('success')
                                                      console.log('destroy '+rns)
                                                      clearInterval(this.getStatusInt)
                                                      this.getStatusInt = null
                                                      console.log(this.getStatusInt)
                                                      this.getStatusInt = null
                                                      //getStatusInt = null
                                                      //this.globalbusy = false
                                                      this.$toasted.success(result.message).goAway(3000)              
                                                      this.onSuccessPay()
                                                      break
                                                    case 'ready':
                                                      console.log(result.data)
                                                      //this.globalbusy = false
                                                      clearInterval(this.getStatusInt)
                                                      this.getStatusInt = null
                                                      this.$toasted.error(result.message).goAway(3000)
                                                      this.onSuccessPay()
                                                      break
                                                    default:
                                                      //this.globalbusy = false
                                                      console.log(result)  
                                                  } 

                                            })
                                            .catch(error => {
                                              this.globalbusy = false
                                              console.error('There was an error!', error)
                                            });
                                      }, 2000);*/
                                  }.bind(this)
                                ).on("liqpay.ready", function(data){
                                  console.log('Ready')  
                                  console.log(data)
                                  this.widget_is_visible = true
                                  }.bind(this)
                                ).on("liqpay.close", function(data){
                                    console.log('Close')
                                    console.log(data)
                                });
                      }
                     //-------------------
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
    },

    onSuccessPay(){
      this.init_order() 
      this.selectedTopay = []
      /*
      console.log('Before')
      console.log(this.checks)
      console.log(this.orders)
      this.selectedTopay.forEach((itm) => {
        switch (itm.charAt(0)) {
          case 'c':
            delete this.checks[itm.substring(1)]
            console.log('delete c '+itm)
            break
          case 'o': 
            delete this.orders[itm.substring(1)] 
            console.log('delete o '+itm)
            break
        }
      })
      this.selectedTopay = []
      console.log('After')
      console.log(this.checks)
      console.log(this.orders)
      */
    },
  /*  getMenu2(){
       this.$axios.post('/', {"action":"getmenu","rn":"005VV"} )
                  .then((response) => {
                    this.posts  = response.data
                    this.dishes = this.posts.dishes
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });
    },*/
    changeSalePoint(rn){
      this.init(rn,null)
    },
    changeMealtime(rn){
      if(this.settings.ask_when_basket_not_empty && this.cart.length>0) {
        this.showMsgBoxRequestSaveOrder(null,null,rn)
      }  
      else this.init(null,null,rn)
    },
    changeOndate(d){
      if(this.settings.ask_when_basket_not_empty && this.cart.length>0) {
        this.showMsgBoxRequestSaveOrder(null,d)
      }  
      else this.init(null,d)
    },
    setNextDay(){
      if(this.ondateTabIndex < this.ondates.length-1){
        //this.init(null,this.ondates[this.ondateTabIndex+1])
        this.changeOndate(this.ondates[this.ondateTabIndex+1])
      }
    },
    setPrevDay(){
      if(this.ondateTabIndex > 0){
        //this.init(null,this.ondates[this.ondateTabIndex-1])
        this.changeOndate(this.ondates[this.ondateTabIndex-1])
      }
    },
    setCategory(rn){
      this.categories = []
      this.categories = this.post.categories.filter(item => item.rn==rn)
    },
    isEmptyCategory(ct){
      var ret = true
      ct.childs.forEach((vale) => {
        Object.keys(this.dishes).forEach((vali) => {
          if(this.dishes[vali].rp === vale){
            ret = false
          }
        })
      })
      return ret
    },
    serviceMessage(msg){
      if(!this.settings || !this.settings.disable_service_messages){
        this.$toasted.success(msg).goAway(3000)
      }else if(this.settings && this.settings.disable_service_messages){
        console.log('SERVICE',msg)
      }
    },

        //this.$toasted.success('success').goAway(2000)
        //this.$toasted.error('error').goAway(3000)
        //this.$toasted.info('info').goAway(5000)

    dateDisabled(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        //console.log(date)
        // const weekday = date.getDay()
        var dt = '' + date.getFullYear()
        dt += (date.getMonth()<9)?'-0':'-'
        dt += date.getMonth()+1
        dt += (date.getDate()<10)?'-0':'-' 
        dt += date.getDate()
        //console.log(dt)
        //console.log((this.holidays[dt]!=null)?'true':'false')
        //const day = date.getDate()
        // Return `true` if the date should be disabled
        //return weekday === 0 || weekday === 6 || day === 13
        //return weekday === 0
        //return this.holidays[dt] != null
        return !this.ondates.includes(dt)
    },
    scrollIntoView(href) {
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    },
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    showDish(rn){
      this.target_dish = rn
      //this.gloibalbusy = true
      //this.complexbusy = true
      this.loadTargetMoreInfo(this.target_dish)
      if(this.dishes[rn] && (this.dishes[rn]).complex && (this.dishes[rn]).complexType===2)
        this.$bvModal.show('target-freecomplex-modal')
      else
        this.$bvModal.show('dish-target-modal')  
    },
    openRamark(rn){

      this.dishComment = []
      this.dishCommentManual = ''

      var comment = null

      this.cart.forEach((itm) => {
        if(!comment && itm.menu_rn===rn){
          console.log('Comment',itm.comments)
          if(itm.comments){
            comment = itm.comments.split(';')
            this.dishComment = (comment[1])?(comment[1]).split(','):[];
            this.dishCommentManual = comment[0]
          }  
        }

      })
      this.num2remark=rn
      this.num2remarkshow=true
    },

    saveRemark(){
      this.setCartComment(this.num2remark,this.dishCommentManual.replace(";",",")+";"+this.dishComment.join(','))
      this.num2remarkshow=false;this.num2remark=null
      this.dishComment = []
      this.dishCommentManual = null
    },
    showDebug(){
      if(this.debugmode) this.$bvModal.show('debug-modal')
    },
    //************************************************ 
    clickLeft() {

      if(!this.navElement){
        if(this.$refs["ondates-tabs"])
          this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
      }    
      this.navElement.scrollLeft -= 50;
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px') 
    },
    clickRight() {

      if(!this.navElement){
        if(this.$refs["ondates-tabs"])
          this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
      }    

      this.navElement.scrollLeft += 50;
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px')
    },

    //
    loadTargetMoreInfo(trgt){
      //this.target_dish_quant = (dishes[target_dish]).quant_cart
      console.log('Target more info',trgt)
      if((this.dishes[trgt]).complex && (this.dishes[trgt]).complexType!=2){
        console.log('COMPLEX')
      }else{ 
        this.complexbusy=false
        return
      }
      var cmarr=[]
      //this.targetComplex=[]
      this.dishes[trgt].complexParts.forEach((cm) => {
        //if(!this.dishes[cm.menuRN] && !this.dishes_complex[cm.menuRN]){
        if(!this.dishes_complex[cm.menuRN]){  
          cmarr.push(cm.menuRN)
          //console.log('NOT Exist',cm.menuRN)
        //}else if(this.dishes[cm.menuRN]){
        //  this.dishes_complex[cm.menuRN] = this.dishes[cm.menuRN]
        //  //console.log('Found id dishes ',this.dishes[cm.menuRN])
        //}else{
          //console.log('Exist',this.dishes[cm.menuRN])
          //this.targetComplex.push(this.dishes[cm.menuRN])
        }
      })

      if(cmarr.length>0){
        console.log('Request for new info',cmarr)
        //--------------------------------------------
        var axiosConfig = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer_'+this.token
          },
          'withCredentials': true
        }
        var postData = {"action":"getcomplex","rn":cmarr.join(',')}
        this.$axios.post('/', postData,  axiosConfig)
                        
                .then((response) => {
                    if(response.data.success != 'false'){
                      var complex   = response.data.complex
                      Object.keys(complex).forEach((c)=>{
                        this.dishes_complex[c] = complex[c]
                        //this.targetComplex.push(complex[c])
                      })
                      //this.$nextTick(() => {
                        this.complexbusy = false
                        console.log('Complex loaded',this.dishes_complex)
                        //this.globalbusy = false
                        //this.$bvModal.show('dish-target-modal')
                      //})
                      
                    }else{
                      this.complexbusy = false
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.needlogin = true
                      }
                    }
                  })
                  .catch(error => {
                    console.error('There was an error!', error)
                  });




        //--------------------------------------------
      }else
        console.log('No need to Request for new info')
        this.complexbusy=false

    },

    complexBannerVisibility(rn,action){

      if(this.settings.hide_fixed_complex_banner) return
      var el = document.getElementById('complex-banner-'+rn)
      if(!el) return

      switch (action){
        case 'on':
          if(this.dishes_complex_parts_visibility[rn]){
            this.dishes_complex_parts_visibility[rn]=false
            this.$root.$emit('bv::toggle::collapse', 'complex-banner-'+rn)
            this.$root.$emit('bv::toggle::collapse', 'complex-parts-'+rn)
            console.log('-----on')
          }  
          break
        case 'off':    
          if(!this.dishes_complex_parts_visibility[rn]){
            this.dishes_complex_parts_visibility[rn]=true
            this.$root.$emit('bv::toggle::collapse', 'complex-banner-'+rn)
            this.$root.$emit('bv::toggle::collapse', 'complex-parts-'+rn)
            console.log('-----off')
          }  
      }
    },

/*
    onTouchStart(event) {
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onTouchMove(event) {
      event.preventDefault();
      const x = event.touches[0].pageX - this.navElement.offsetLeft;
      const speed = 1; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
    },
    onMouseDown(event) {
      this.isMouseDown = true;

      this.startX = event.pageX - this.navElement.offsetLeft;
      this.scrollLeft = this.navElement.scrollLeft;
    },
    onMouseUp() {
      this.isMouseDown = false;
    },
    onMouseLeave() {
      this.isMouseDown = false;
    },
    onMouseMove(event) {
      if (!this.isMouseDown) return;

      event.preventDefault();
      const x = event.pageX - this.navElement.offsetLeft;
      const speed = 1.5; // higher number means faster scrolling
      const walk = (x - this.startX) * speed;
      this.navElement.scrollLeft = this.scrollLeft - walk;
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
    },
*/

    //*************************************************
    /*
    showPayWindow(rn) {
 
      if(this.ordfood.length + this.checkbase.length > 0){

        if(this.ordfood.length > 0 && !this.ordfood[0].paytime) {
          this.globalbusy = true
          rn = this.ordfood[0].rn
          //if(this.checkbase.length > 0) rn = this.checkbase[0].rn
          this.$axios.post('/', {"action":"payreservedorder","rn":rn} )
                  .then((response) => {
                    if(response.data.success != 'false'){ 
                      console.log(response.data)
                      this.globalbusy = false
                      //this.$bvModal.show('orders-modal')            
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#liqpay_checkout",
                        "language": "uk",
                        "mode": "popup" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)
                      }).on("liqpay.ready", function(data){
                          // ready
                          console.log('Ready')
                          console.log(data)
                      }).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth"){
                        this.needlogin = true
                      }else if(response.data.error=="tokenout"){
                        this.needlogin = true
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
          }else{
            // no unpayed order
          }
      }else{
        // no unpayed
      }





      
                  
    }
  */
    

  }
   
}
</script>

<style scope>
  #appc {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding-top: 80px;
  }
  .quant {
    min-width: 16px;
    height: 16px;
    position: absolute;
/*    top: 10px;
    right: 0px;*/
    top: 6px;
    left: 16px;
    background: #d00;
    border-radius: 10px;
    font-family: "SegoeUISemiBold",sans-serif;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
  }
  .add_cart_item{
    /*
    position:absolute;
    bottom:0px;
    right:31px;
    */

      position: relative;

    padding:0;
    border:0;
  }
  .item_quant {
    min-width: 16px;
    height: 16px;
    /*
    position: absolute;
    bottom: 25px;
    right: -6px;
    */
        position: absolute;
        top: -3px;
        right: -6px;

    background: rgb(5, 128, 32);
    border-radius: 10px;
    font-family: "SegoeUISemiBold",sans-serif;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-align: center;
  }
  .button-cart {
    position:relative;
  }
  /*
  .error {
   animation-name: errorShake;
   animation-duration: 0.3s;
  }*/

  @keyframes errorShake {
    0% {
        transform: translateX(-25px);
    }
    25% {
        transform: translateX(25px);
    }
    50% {
        transform: translateX(-25px);
    }
    75% {
        transform: translateX(25px);
    }
    100% {
        transform: translateX(0);
    }
  }
  .ico-remove-item {
    position: absolute;
    top:-2px;
    right: 7px;
    font-size: 1.8rem;
  }
  .dish_smarks,.dish_complex_smarks{
    position: absolute;
    top:-3px;
    left: 10px;
  }
  .dish_complex_smarks{
    left: 34px;
  }
  .mark_new {
    /*
    position: absolute;
    height: 50px;
    top: -13px;
    left: 0;
    */
    background-color: blue;
    color: white;
  }
  .mark_sale {
    /*
    position: absolute;
    height: 40px;    
    top:1px;
    left:0;
    */
    background-color: magenta;
    color: white;
  }
  .mark_vegan {
    /*
    position: absolute;
    height: 40px;    
    top:2px;
    left:0;
    */
    background-color: green;
    color: white;
  }
  .mark_18+ {
    /*
    position: absolute;
    height: 40px;    
    top:3px;
    left:0;
    */
    background-color: black;
    color: white;
  }
  .mark_spicy{
    /*
    position: absolute;
    height: 40px;    
    top:0;
    left:0;
    */
    background-color: red;
    color: white;
  }

  .dish_complex{
    position: absolute;
    top: -2px;
    left: 12px;
    font-size: 1.2em;
  }


  /**/
.navbar-nav .dropdown-menu {
    position: absolute;
}

.discounted{
  text-decoration: line-through;
  text-decoration-color: #d00;
  position: relative;
  top: -10px;
  color: #999;
}

.orders-specs-row{
  background-color:#dcdcdc;
}
.orders-specs-row .td-active{
  cursor: pointer;
}
.order-failed td{
  text-decoration: line-through;
  cursor:not-allowed;
}


.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.custom-select > option:disabled{
  color:#ccc;
}

.min-vh-70 {
    min-height: 70vh!important;
}

.orders-menu-spec{
  font-size: 15px;
  font-weight: 400;
}

.orders-table, .orders-table td{
  border-width: 0;
  border-spacing: 0px;
}


/*=============================================================== */

svg.ico-edit-zakaz{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  width: 30px;
}  


.orders-menu-spec > div{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
}

td.orders-menu-spec-td:hover .orders-menu-spec svg.ico-edit-zakaz{
  visibility: visible;
  opacity: 1;
}

@media (hover: none) {
  svg.ico-edit-zakaz{
    visibility: visible;
    opacity: 1;
  }    

}    



/*
.nav-tabs {
  display: none;
}

@media (min-width: 768px) {
  .nav-tabs {
    display: flex;
    flex-flow: column nowrap;
  }
  .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #ddd;
    display: flex;
  }
  .nav-tabs {
    margin: 0 15px;
  }
  .nav-tabs .nav-item + .nav-item {
    margin-top: 0.25rem;
  }
  .nav-tabs .nav-link {
    transition: border-color 0.125s ease-in;
    white-space: nowrap;
  }
  .nav-tabs .nav-link:hover {
    background-color: #f7f7f7;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active {
    border-bottom-color: #ddd;
    border-right-color: #fff;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    margin-right: -1px;
  }
  .nav-tabs .nav-link.active:hover {
    background-color: #fff;
    border-color: #0275d8 #fff #0275d8 #0275d8;
  }

  .card {
    border: none;
  }

  .card .card-header {
    display: none;
  }

  .card .collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .tab-pane {
    display: block !important;
    opacity: 1;
  }
}
*/

/*-------------------------------------------*/


  
</style>
