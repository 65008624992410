<template>
    <div class="legacy" v-html="legacy_text"></div>
</template>

<script>

export default {
  name: 'LegacyText',
  props: {
    user_block:{
        type: Object,
        required: true
    }
  },
  data: () => ({
    legacy_text: null,
    busy: null
  }),  
  methods: {
        getLegacy(){
            this.busy = true;
            this.$axios.post('/', {"action":"getuserblock","rn":this.user_block} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.legacy_text = response.data.legacy
                    this.busy = false;
                }else{
                    this.busy = false;
                    if(response.data.message)
                        this.$toasted.error(response.data.message).goAway(3000) 

                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        }
    
  },
  
  mounted() {
      this.getLegacy()
  },
   
}
</script>

<style scoped>

@media (max-width: 575px) {

}

@media (hover: none) {


}    



</style>
